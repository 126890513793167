import React, { useContext } from 'react';
import {
  Input,
  InputLabel,
  IconButton,
  Typography,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import Papa from 'papaparse';
import { camelCase } from 'change-case';
import { errorMsg } from './SnackbarUtilsConfigurator';
import { PartialAggrPropertyRowWithOrder } from './EditableTable';
import { AggrPropertyRow } from '../utils/types';
import { objectKeysToLowerCase } from '../utils/objectKeysToLowerCase';
import { ValidOptions } from '../pages/aggregates/components/ValidOptionsCustomInput';
import { ConfigContext } from '../context/ConfigContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/pro-regular-svg-icons';
import { colors } from 'utils/colors';

interface CSVResult {
  newRecordCount: number;
  existingRecordCount: number;
  badRecordCount: number;
  afterImportCount: number;
  badRecords: string;
}

interface BadRecords {
  name?: string;
  rowIndex?: number;
}

interface PropertyListTableProps {
  data: PartialAggrPropertyRowWithOrder[];
  fileImportResults?: (
    rows: object[],
    uploadedResults: CSVResult,
    resolve: (data: any) => void,
    reject: () => void
  ) => void;
}

interface Row {
  [key: string]: string | boolean | number | string[] | undefined | ValidOptions;
}

export const PropertyListUpload: React.FC<PropertyListTableProps> = ({
  data,
  fileImportResults,
}) => {
  const { getAggregates } = useContext(ConfigContext);

  let badRecords: object[] = [];
  const BAD_RECORD = 'BAD_RECORD';
  const onCSVImportClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (file) {
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: function (results: Papa.ParseResult<Row>) {
          const csvData = convertObjsKeysToLowerCase(results.data);
          const missedLableRowNo = checkMandateLabels(csvData);
          if (isAggregateProperty(csvData) && missedLableRowNo.length === 0) {
            const correctedCsvData = csvDataCorrection(csvData);
            restructureCsvToTableData(correctedCsvData);
          } else {
            errorMsg(
              `Please enter labels from each Row that is missing : ${missedLableRowNo.toString()}`
            );
          }
        },
        header: true,
      });
    }
  };

  /**
   *  check csv columns(nullable,filterable,editable,hideFromAssetAttributes,includeInFullText) weather it holds boolean value or not and return boolean if exists else add it in Bad Record Array.
   * @param val The csv cell data to validate
   * @param columnName The columnName which holds val
   * @param name The column name, to eliminitate bad record getting added while uploading.
   * @param rowIndex The rowIndex, to highlight csv bad record in csvResult dialogue
   * @param existingRecordCount checks csv Record against table Record
   */
  const checkBooleanColumnVal = (
    val: string | boolean | undefined,
    columnName:
      | 'nullable'
      | 'indexed'
      | 'filterable'
      | 'editable'
      | 'hideFromAssetAttributes'
      | 'includeInFullText'
      | 'excludeFromMobile',
    name: string,
    rowIndex: number,
    existingRecordCount: number
  ): boolean | 'BAD_RECORD' | undefined => {
    const tableData = data;
    if (val === '' || val === undefined) {
      if (existingRecordCount !== -1) {
        return tableData[existingRecordCount][columnName];
      } else {
        if (columnName === 'nullable') {
          return true;
        } else {
          return false;
        }
      }
    } else if (typeof val === 'string') {
      //badRecords
      val = val.toLowerCase();
      if (val === 'true' || val === 'false') {
        return JSON.parse(val);
      } else {
        rowIndex = rowIndex + 2;
        badRecords.push({ name, rowIndex });
        return BAD_RECORD;
      }
    } else {
      return val;
    }
  };

  /**
   *  checks all the csv row data, make the corrections and assign valid data to each csv row
   * @param csvData The csvData for correction
   */

  const csvDataCorrection = (csvData: AggrPropertyRow[]) => {
    badRecords = [];
    const tableData: PartialAggrPropertyRowWithOrder[] = data;
    const newCsvData: AggrPropertyRow[] = [];
    csvData.forEach((row: AggrPropertyRow, rowIndex: number) => {
      let isFilterable: boolean | 'BAD_RECORD' | undefined = false;
      const outputRow: Partial<AggrPropertyRow> = {};

      outputRow.label = row.label;

      if (row.hasOwnProperty('name')) {
        if (!row.name) {
          outputRow.name = camelCase(`${row.label}`);
        } else {
          outputRow.name = camelCase(row.name);
        }
      } else {
        outputRow.name = camelCase(`${row.label}`);
      }

      const existingRecordIndex = tableData.findIndex((tableRow) => tableRow.name === row.name);

      if (!row.hasOwnProperty('foreignColumn')) {
        if (existingRecordIndex !== -1 && tableData[existingRecordIndex].foreignColumn) {
          outputRow.foreignColumn = tableData[existingRecordIndex].foreignColumn;
        }
      } else {
        if (row.foreignColumn) {
          outputRow.foreignColumn = row.foreignColumn;
        }
      }

      if (row.hasOwnProperty('type')) {
        if (!row.type) {
          existingRecordIndex !== -1
            ? (outputRow.type = tableData[existingRecordIndex].type)
            : (outputRow.type = 'String');
        } else {
          const enums = [
            'ID',
            'String',
            'Int',
            'Float',
            'DateTime',
            'Date',
            'Boolean',
            'JSON',
            'Geography',
            'PhotoCollection',
            'FileCollection',
          ];
          getAggregates().forEach((aggr) => enums.push(aggr.typeName));
          if (enums.indexOf(row.type) === -1) {
            //increment by 1 for table header and other Array index 0
            const index = rowIndex + 2;
            badRecords.push({ name: outputRow.name, rowIndex: index });
            return;
          } else {
            outputRow.type = row.type;
          }
        }
      } else {
        existingRecordIndex !== -1
          ? (outputRow.type = tableData[existingRecordIndex].type)
          : (outputRow.type = 'String');
      }
      if (row.hasOwnProperty('nullable')) {
        const evaluatedProp = checkBooleanColumnVal(
          row.nullable,
          'nullable',
          outputRow.name,
          rowIndex,
          existingRecordIndex
        );
        if (evaluatedProp === BAD_RECORD) {
          return;
        } else {
          outputRow.nullable = evaluatedProp;
        }
      } else {
        existingRecordIndex !== -1
          ? (outputRow.nullable = tableData[existingRecordIndex].nullable)
          : (outputRow.nullable = true);
      }
      if (row.hasOwnProperty('filterable')) {
        isFilterable = checkBooleanColumnVal(
          row.filterable,
          'filterable',
          outputRow.name,
          rowIndex,
          existingRecordIndex
        );
        if (isFilterable === BAD_RECORD) {
          return;
        } else {
          if (isFilterable) outputRow.filterable = isFilterable;
        }
      } else {
        if (existingRecordIndex !== -1 && tableData[existingRecordIndex].filterable)
          outputRow.filterable = tableData[existingRecordIndex].filterable;
      }

      if (row.hasOwnProperty('filterOptions')) {
        if (row.filterOptions !== undefined || row.filterOptions !== null) {
          const enums = ['', 'DISTINCT'];
          if (enums.indexOf(row.filterOptions as string) === -1) {
            const index = rowIndex + 2;
            badRecords.push({ name: outputRow.name, rowIndex: index });
          } else {
            if (row.filterOptions) {
              outputRow.filterOptions = row.filterOptions;
            }
          }
        }
      } else {
        if (existingRecordIndex !== -1 && tableData[existingRecordIndex].filterOptions)
          outputRow.filterOptions = tableData[existingRecordIndex].filterOptions;
      }

      if (row.hasOwnProperty('editable')) {
        const evaluatedProp = checkBooleanColumnVal(
          row.editable,
          'editable',
          outputRow.name,
          rowIndex,
          existingRecordIndex
        );
        if (evaluatedProp === BAD_RECORD) {
          return;
        } else if (evaluatedProp) {
          outputRow.editable = evaluatedProp;
        }
      } else {
        if (existingRecordIndex !== -1 && tableData[existingRecordIndex].editable)
          outputRow.editable = tableData[existingRecordIndex].editable;
      }
      if (row.hasOwnProperty('relation')) {
        if (row.relation !== undefined || row.relation !== null) {
          const enums = ['', 'ONE-TO-ONE', 'ONE-TO-MANY']; // , 'MANY-TO-MANY']; TODO: Add many to many back in when it's needed
          if (enums.indexOf(row.relation as string) === -1) {
            const index = rowIndex + 2;
            badRecords.push({ name: outputRow.name, rowIndex: index });
          } else {
            if (row.relation) {
              outputRow.relation = row.relation;
            }
          }
        }
      } else {
        if (existingRecordIndex !== -1 && tableData[existingRecordIndex].relation)
          outputRow.relation = tableData[existingRecordIndex].relation;
      }
      if (row.hasOwnProperty('indexed')) {
        const evaluatedProp = checkBooleanColumnVal(
          row.indexed,
          'indexed',
          outputRow.name,
          rowIndex,
          existingRecordIndex
        );
        if (evaluatedProp === BAD_RECORD) {
          return;
        } else if (evaluatedProp) {
          outputRow.indexed = evaluatedProp;
        }
      } else {
        if (existingRecordIndex !== -1 && tableData[existingRecordIndex].indexed)
          outputRow.indexed = tableData[existingRecordIndex].indexed;
      }

      if (row.hasOwnProperty('hideFromAssetAttributes')) {
        const evaluatedProp = checkBooleanColumnVal(
          row.hideFromAssetAttributes,
          'hideFromAssetAttributes',
          outputRow.name,
          rowIndex,
          existingRecordIndex
        );
        if (evaluatedProp === BAD_RECORD) {
          return;
        } else if (evaluatedProp) {
          outputRow.hideFromAssetAttributes = evaluatedProp;
        }
      } else {
        if (existingRecordIndex !== -1 && tableData[existingRecordIndex].hideFromAssetAttributes)
          outputRow.hideFromAssetAttributes =
            tableData[existingRecordIndex].hideFromAssetAttributes;
      }
      if (row.hasOwnProperty('includeInFullText')) {
        const evaluatedProp = checkBooleanColumnVal(
          row.includeInFullText,
          'includeInFullText',
          outputRow.name,
          rowIndex,
          existingRecordIndex
        );
        if (evaluatedProp === BAD_RECORD) {
          return;
        } else if (evaluatedProp) {
          outputRow.includeInFullText = evaluatedProp;
        }
      } else {
        if (existingRecordIndex !== -1 && tableData[existingRecordIndex].includeInFullText)
          outputRow.includeInFullText = tableData[existingRecordIndex].includeInFullText;
      }
      if (row.hasOwnProperty('excludeFromMobile')) {
        const evaluatedProp = checkBooleanColumnVal(
          row.excludeFromMobile,
          'excludeFromMobile',
          outputRow.name,
          rowIndex,
          existingRecordIndex
        );
        if (evaluatedProp === BAD_RECORD) {
          return;
        } else if (evaluatedProp) {
          outputRow.excludeFromMobile = evaluatedProp;
        }
      } else {
        if (existingRecordIndex !== -1 && tableData[existingRecordIndex].excludeFromMobile)
          outputRow.excludeFromMobile = tableData[existingRecordIndex].excludeFromMobile;
      }

      if (row.hasOwnProperty('validOptions')) {
        if (row.validOptions) {
          outputRow.validOptions = { enum: [] };
          outputRow.validOptions.enum = ((row.validOptions as unknown) as string).split(',');
        }
      }

      if (row.hasOwnProperty('uiType')) {
        if (!row.uiType) {
          if (existingRecordIndex !== -1 && tableData[existingRecordIndex].uiType)
            outputRow.uiType = tableData[existingRecordIndex].uiType;
        } else {
          const enums = ['Symbol Key', 'Title', 'Hidden', 'DateTime', 'Date', 'JSON'];
          if (enums.indexOf(row.uiType) === -1) {
            const index = rowIndex + 2;
            badRecords.push({ name: outputRow.name, rowIndex: index });
          } else {
            outputRow.uiType = row.uiType;
          }
        }
      } else {
        if (existingRecordIndex !== -1 && tableData[existingRecordIndex].uiType)
          outputRow.uiType = tableData[existingRecordIndex].uiType;
      }
      newCsvData.push(outputRow as AggrPropertyRow);
    });

    badRecords.forEach((rowObj: BadRecords) => {
      const badRecordIndex = newCsvData.findIndex(
        (csvRowData: AggrPropertyRow) => rowObj.name === csvRowData.name
      );
      if (badRecordIndex !== -1) {
        newCsvData.splice(badRecordIndex, 1);
      }
    });
    return newCsvData;
  };

  /** This method is responsible for records ordering, remove duplicate records by comparing table data vs csv Data, send final data along with meta results(uploadedResults) and send to parent component.
   * @param csvData The csv Data
   */
  const restructureCsvToTableData = (csvData: AggrPropertyRow[]) => {
    const badRecordRows = badRecords.map((rowObj: BadRecords) => rowObj.rowIndex);
    let tableData = data;
    const uploadedResults: CSVResult = {
      newRecordCount: 0,
      existingRecordCount: tableData.length,
      afterImportCount: 0,
      badRecordCount: badRecordRows.length,
      badRecords: badRecordRows.toString(),
    };

    csvData.forEach((csvRow: AggrPropertyRow) => {
      const existingRecordIndex = tableData.findIndex((tableRow) => tableRow.name === csvRow.name);
      if (existingRecordIndex !== -1) {
        tableData.splice(existingRecordIndex, 1);
      } else {
        uploadedResults.newRecordCount = uploadedResults.newRecordCount + 1;
      }
    });

    tableData = [...csvData, ...tableData];

    tableData = tableData.map(({ order, ...rest }) => rest);

    uploadedResults.afterImportCount =
      uploadedResults.existingRecordCount + uploadedResults.newRecordCount;

    return new Promise((resolve, reject) => {
      if (fileImportResults) {
        fileImportResults(tableData, uploadedResults, resolve, reject);
      }
    });
  };

  const checkMandateLabels = (data: Row[]) => {
    const missedLableIndex: number[] = [];
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      if (!row.label) {
        const index = i + 2;
        missedLableIndex.push(index);
      }
    }
    return missedLableIndex;
  };

  const convertObjsKeysToLowerCase = (csvData: Row[]) =>
    csvData.map((row) => objectKeysToLowerCase(row));

  const isAggregateProperty = (value: Row[]): value is AggrPropertyRow[] => 'label' in value[0];
  const classes = useStyles();
  return (
    <InputLabel htmlFor="csvImportBtn" style={styles.mainContainer}>
      <IconButton component="span" className={classes.button}>
        <Input
          id="csvImportBtn"
          onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
            if (e) {
              (e.target as HTMLInputElement).value = '';
            }
          }}
          inputProps={{
            accept: '.csv',
          }}
          onChange={onCSVImportClick}
          style={styles.csvButton}
          type="file"
        />

        <FontAwesomeIcon icon={faFileImport} className={classes.importIcon} />
        <Typography style={styles.textContainers}>Import</Typography>
      </IconButton>
    </InputLabel>
  );
};
const styles = {
  textContainers: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '100%',
    marginLeft: 8,
  },
  csvButton: {
    display: 'none',
  },
  mainContainer: {
    alignSelf: 'center',
    cursor: 'pointer',
    width: 'fit-content',
    height: 40,
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: colors.white,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: colors.greyBorder,
      color: colors.greyText,
      borderRadius: 4,
      fontSize: 16,
      fontWeight: 500,
      fontStyle: 'normal',
      height: 40,
    },
    importIcon: {
      fontSize: 21,
    }
  })
);
