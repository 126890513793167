import { JSONSchema6 } from 'json-schema';
import {
  NodeMapDefinition,
  SchemaDefinition,
  SchemaLookup,
} from '@terragotech/gen5-datamapping-lib';
import { useConfig } from '../context/ConfigContext';
import { useMemo } from 'react';
import { propertiesToSchema } from '../pages/aggregates/utils/PropertiesToSchemaConverter';
import { generateEventSchema } from './jsonPartsGenerators';
import { EventDefinition, EventVersionDefinition } from '@terragotech/gen5-config-lib';

export interface LocalSchemaDefinition {
  [index: string]: {
    schema: JSONSchema6;
    schemaLabel: string;
    label?: string;
  };
}
interface UseSchemaLookupProps {
  localSchemas?: LocalSchemaDefinition;
  currentAggregateType?: string;
  commandId?: string;
}
const defaultEventPrefix = 'generatedDefault';
function getDefaultEventName(type: string, commandId: string) {
  return `${defaultEventPrefix}${type}${commandId[0].toUpperCase() + commandId.substring(1)}`;
}
/**
 * This takes in specific schemas, plus looks at the existing config to grab any other schemas available in the current configuration
 * @param localSchemas array of scenario specific schemas
 * @param currentAggregateType The currently selected aggreagte type, used to decide which schemas to make available
 * @returns the schema lookup implementation used by the mapper
 */
export const useSchemaLookup = (props: UseSchemaLookupProps): SchemaLookup => {
  const { getAggregates, getConfig, getEvent, setEvent } = useConfig();
  const clonedConfig = getConfig();
  const lookup: SchemaLookup = useMemo(() => {
    const schemas: LocalSchemaDefinition = { ...props.localSchemas };
    return {
      getAggregates: () => {
        return getAggregates().map((agg) => agg.typeName);
      },
      getNextDefault: (aggregateType: string) => {
        const idx = getAggregates().findIndex((agg) => agg.typeName === aggregateType);
        const eventName = getDefaultEventName(
          props.currentAggregateType || '',
          props.commandId || ''
        );
        const event = getEvent(idx, eventName);
        const num =
          (event?.versions || []).reduce((prev, curr) => Math.max(prev, curr.versionNumber), 0) + 1;
        return `${aggregateType}::${eventName}::${num}`;
      },
      getSchema: (schemaId) => {
        if (schemas[schemaId]) {
          return schemas[schemaId].schema;
        } else {
          const parts = schemaId && schemaId.split('::');
          if (parts && parts.length >= 1) {
            // Form: <Aggregate>::<Event>::<Event version>
            const [aggregateKey, eventKey, eventVersionNumber] = parts;
            const aggregate = getAggregates().find((agg) => agg.typeName === aggregateKey);
            if (aggregate) {
              const event = aggregate.events?.[eventKey];
              if (event) {
                const eventVersion = event.versions.find(
                  (eventVer) => eventVer.versionNumber === Number.parseInt(eventVersionNumber)
                );
                //TODO: Default to the latest version if none provided
                return eventVersion ? eventVersion.eventSchema : {};
              } else if (eventKey === 'DeleteEvent') {
                return generateEventSchema('DeleteEvent', {});
              } else {
                return propertiesToSchema(aggregate.properties);
              }
            }
          }
        }
        return {};
      },
      createNewDefaultEvent: (aggregateType?: string) => {
        const idx = getAggregates().findIndex((agg) => agg.typeName === aggregateType);
        const aggregate = getAggregates().find((agg) => agg.typeName === aggregateType);
        if (!aggregate) {
          return '';
        }
        const aggregateMap = {
          outputDefinition: { outputNode: 'OUTPUT' },
          nodes: {
            EVENT: {
              type: 'EVENT',
              name: 'Event',
            },
            ConditionalMapper: {
              type: 'CONDITIONAL-MAPPER',
              name: 'Conditional',
              inputs: {
                booleanSource: {
                  sourceObject: 'Exists',
                  sourcePath: '$.exists',
                },
                falseSource: {
                  sourceObject: 'EVENT',
                  sourcePath: '$.aggregateId',
                },
                trueSource: {
                  sourceObject: 'EVENT',
                  sourcePath: '$.data.id',
                },
              },
            },
            Exists: {
              type: 'TEXT-EXISTS',
              name: 'Exists',
              inputs: {
                source: {
                  sourceObject: 'EVENT',
                  sourcePath: '$.data.id',
                },
              },
            },
            OUTPUT: {
              type: 'OBJECT-BUILDER',
              config: {
                objectSchema: 'STATE',
              },
              inputs: Object.keys(aggregate.properties).reduce((prev, key) => {
                let n = {};
                if (key === 'id') {
                  n = {
                    sourceObject: 'ConditionalMapper',
                    sourcePath: '$.output',
                  };
                } else {
                  n = {
                    sourceObject: 'EVENT',
                    sourcePath: '$.data.' + key,
                  };
                }
                return {
                  ...prev,
                  [key]: n,
                };
              }, {}),
            },
          },
        };
        const eventName = getDefaultEventName(
          props.currentAggregateType || '',
          props.commandId || ''
        );
        const event: EventDefinition = getEvent(idx, eventName) || {
          versions: [],
        };

        const newVer: EventVersionDefinition = {
          eventSchema: generateEventSchema(eventName, propertiesToSchema(aggregate.properties)),
          versionNumber:
            event.versions.reduce((prev, curr) => Math.max(prev, curr.versionNumber), 0) + 1,
          aggregateMap: aggregateMap as NodeMapDefinition,
        };

        event.versions.push(newVer);
        setEvent(idx, eventName, event);

        return `${aggregate.typeName}::${eventName}::${newVer.versionNumber}`;
      },
      getSchemas: (options) => {
        if (options?.aggregateType) {
          const aggSchemas: LocalSchemaDefinition = { ...props.localSchemas };
          const aggregate = getAggregates().find((agg) => agg.typeName === options.aggregateType);
          // once an aggregate is found, we need to add schemas for all available events of that aggregate
          const events = aggregate?.events;
          const defaultEventName = getDefaultEventName(
            props.currentAggregateType || '',
            props.commandId || ''
          );
          if (aggregate && events) {
            let maxIdx = 0;
            Object.keys(events).forEach((key) => {
              //add event to schema
              const event = events[key];
              event.versions.forEach((version) => {
                aggSchemas[`${aggregate.typeName}::${key}::${version.versionNumber}`] = {
                  schema: version.eventSchema,
                  schemaLabel: `${key}: V${version.versionNumber}`,
                };
                if (key === defaultEventName) {
                  maxIdx = Math.max(version.versionNumber, maxIdx);
                }
              });
              //Add static DeleteEvent
              aggSchemas[`${aggregate.typeName}::DeleteEvent::1`] = {
                schema: generateEventSchema('DeleteEvent', {}),
                schemaLabel: `DeleteEvent: V1`,
              };
            });
            //Add new Default Event
            aggSchemas[`${aggregate.typeName}::${defaultEventName}::${maxIdx + 1}`] = {
              schema: generateEventSchema(
                defaultEventName,
                propertiesToSchema(aggregate.properties)
              ),
              schemaLabel: `New Default Event`,
              label: `New Default Event`,
            };
          }
          return Object.keys(aggSchemas)
            .filter((key) => {
              return !key.match(
                new RegExp(
                  `^${options.aggregateType}::${defaultEventPrefix}(?!${
                    props.currentAggregateType
                  }${
                    props.commandId
                      ? props.commandId[0].toUpperCase() + props.commandId.substring(1)
                      : ''
                  })`
                )
              );
            })
            .map((key) => ({
              schemaId: key,
              schemaLabel: aggSchemas[key].schemaLabel,
              label: aggSchemas[key].schemaLabel,
            }));
        }
        const outputSchemas = Object.keys(schemas).map((key) => ({
          schemaId: key,
          schemaLabel: schemas[key].schemaLabel,
          label: schemas[key].schemaLabel,
        }));
        //Also return schema for each aggregate
        getAggregates().forEach((agg) => {
          outputSchemas.push({
            schemaId: agg.typeName,
            schemaLabel: agg.typeName,
            label: agg.typeName,
          });
        });

        return outputSchemas;
      },
      getRelationships: () => {
        //Also return schema for each aggregate
        return getAggregates().reduce<any>((prev,agg) => {
          prev[agg.typeName] = Object.keys(agg.properties).reduce<any[]> ((p,c)=>{
            if((agg.properties[c] as any).relation === 'ONE-TO-MANY'){
              return p.concat({relatedRecordType:agg.properties[c].type,relatedColumn:c})
            }
            if((agg.properties[c] as any).relation === 'ONE-TO-ONE'){
              return p.concat({relatedRecordType:agg.properties[c].type,relatedColumn:c})
            }
            return p;
          },[])
          return prev;
        },{});
      },
      getFunctionInput: (id) => {
        const parts = id && id.split('::');
        if (parts && parts.length >= 1) {
          // Form: <functionName>::<version>
          const [functionName, version] = parts;
          const config = clonedConfig.functions;
          const func = config ? config[functionName] : undefined;
          if (func) {
            const funcVersion = func.versions.find(
              (ver) => ver.versionNumber === Number.parseInt(version)
            );
            if (funcVersion) {
              return funcVersion.input;
            }

            let v: number;
            func.versions.forEach((ver) => {
              v = Math.max(ver.versionNumber, v);
            });
            return func.versions.find((ver) => ver.versionNumber === v)?.input || {};
          }
        }
        return {};
      },
      getFunctionOutput: (id) => {
        const parts = id && id.split('::');
        if (parts && parts.length >= 1) {
          // Form: <functionName>::<version>
          const [functionName, version] = parts;
          const config = clonedConfig.functions;
          const func = config ? config[functionName] : undefined;
          if (func) {
            const funcVersion = func.versions.find(
              (ver) => ver.versionNumber === Number.parseInt(version)
            );
            if (funcVersion) {
              return funcVersion.output;
            }

            let v: number;
            func.versions.forEach((ver) => {
              v = Math.max(ver.versionNumber, v);
            });
            return func.versions.find((ver) => ver.versionNumber === v)?.output || {};
          }
        }
        return {};
      },
      getFunctions: () => {
        const conf = clonedConfig;
        const result: SchemaDefinition[] = [];
        if (conf.functions) {
          Object.keys(conf.functions).forEach((key) => {
            if (conf.functions) {
              conf.functions[key].versions.forEach((item) => {
                result.push({
                  schemaId: key + '::' + item.versionNumber,
                  schemaLabel: key + ' v' + item.versionNumber,
                });
              });
            }
          });
        }
        return result;
      },
    };
  }, [getAggregates, props.localSchemas, getConfig, getEvent, setEvent]);
  return lookup;
};
