import { Gen5Config } from '@terragotech/gen5-config-lib';
import { AggrPropertyRow } from '../../../utils/types';

export const migrateInputJson = (fileJson: Gen5Config): Gen5Config => {
  const aggregates = fileJson.aggregates;
  for (const [index, aggregate] of aggregates.entries()) {
    let properties = aggregate.properties;
    for (const [key, value] of Object.entries(properties as object)) {
      properties = {
        ...properties,
        [key]: migrateGeoGraphyUiType(value as AggrPropertyRow),
      };
    }
    aggregates[index].properties = properties;
  }
  if(!fileJson.functions){
    fileJson.functions = {};
  }
  if(!fileJson.functions.updateProps){
    fileJson.functions.updateProps = {
      "currentVersion": 1,
      "versions": [
        {
          "versionNumber": 1,
          "output": {
            "type": "object",
            "properties": { "result": { "type": "object", "properties": {} } }
          },
          "input": {
            "type": "object",
            "properties": {
              "in": { "type": "object", "properties": {} },
              "data": {
                "type": "array",
                "items": {
                  "type": "object",
                  "properties": {
                    "from": { "type": "string" },
                    "to": { "type": "string" }
                  },
                  "required": ["from", "to"]
                }
              }
            },
            "required": ["data"]
          },
          "aggregateMap": {
            "nodes": {
              "OUTPUT": {
                "type": "OBJECT-BUILDER",
                "config": { "objectSchema": "STATE" },
                "inputs": {
                  "result": {
                    "sourceObject": "newNode1",
                    "sourcePath": "$.reduced"
                  }
                },
                "position": { "x": 994, "y": 133 }
              },
              "DATA": {
                "type": "DATA",
                "config": {},
                "position": { "x": 77, "y": 131 }
              },
              "newNode1": {
                "type": "ARRAY-REDUCE",
                "config": {
                  "type": "Object",
                  "functionId": "updatePropsHelper::1",
                  "inputName": "in",
                  "outputName": "result",
                  "inputData": false
                },
                "inputs": {
                  "input": { "sourceObject": "DATA", "sourcePath": "$.data" },
                  "initial": { "sourceObject": "DATA", "sourcePath": "$.in" }
                },
                "position": { "x": 444, "y": 55 }
              }
            },
            "outputDefinition": { "outputNode": "OUTPUT" }
          }
        }
      ]
    }
  }
  if(!fileJson.functions.updatePropsHelper){
    fileJson.functions.updatePropsHelper = {
      "currentVersion": 1,
      "versions": [
        {
          "versionNumber": 1,
          "output": {
            "type": "object",
            "properties": { "result": { "type": "object", "properties": {} } }
          },
          "input": {
            "type": "object",
            "properties": {
              "in": {
                "type": "object",
                "properties": {
                  "from": { "type": "string" },
                  "to": { "type": "string" }
                },
                "required": ["from", "to"]
              },
              "prev": { "type": "object", "properties": {} }
            },
            "required": ["prev"]
          },
          "aggregateMap": {
            "nodes": {
              "OUTPUT": {
                "type": "OBJECT-BUILDER",
                "config": { "objectSchema": "STATE" },
                "inputs": {
                  "result": {
                    "sourceObject": "newNode3",
                    "sourcePath": "$.result"
                  }
                },
                "position": { "x": 1091, "y": 141 }
              },
              "DATA": {
                "type": "DATA",
                "config": {},
                "position": { "x": 35, "y": 81 }
              },
              "newNode1": {
                "type": "OBJECT-DYNAMIC-LOOKUP",
                "config": {},
                "inputs": {
                  "input": { "sourceObject": "DATA", "sourcePath": "$.prev" },
                  "key": { "sourceObject": "DATA", "sourcePath": "$.in.from" }
                },
                "position": { "x": 275, "y": 10 }
              },
              "newNode2": {
                "type": "OBJECT-SET-KEY",
                "config": {},
                "inputs": {
                  "value": {
                    "sourceObject": "newNode1",
                    "sourcePath": "$.result"
                  },
                  "key": { "sourceObject": "DATA", "sourcePath": "$.in.to" },
                  "input": { "sourceObject": "DATA", "sourcePath": "$.prev" }
                },
                "position": { "x": 497, "y": 319 }
              },
              "newNode3": {
                "type": "OBJECT-SET-KEY",
                "config": {},
                "inputs": {
                  "input": {
                    "sourceObject": "newNode2",
                    "sourcePath": "$.result"
                  },
                  "key": { "sourceObject": "DATA", "sourcePath": "$.in.from" }
                },
                "position": { "x": 706, "y": 35 }
              }
            },
            "outputDefinition": { "outputNode": "OUTPUT" }
          }
        }
      ]
    }
  }
  if (!fileJson.functions.parseDatePropsHelper) {
    fileJson.functions.parseDatePropsHelper = {
      "currentVersion": 1,
      "versions": [
        {
          "versionNumber": 1,
          "output": {
            "type": "object",
            "properties": {
              "result": { "type": "object", "properties": {}, "required": [] }
            },
            "required": ["result"]
          },
          "input": {
            "type": "object",
            "properties": {
              "in": {
                "type": "object",
                "properties": {
                  "key": { "type": "string" },
                  "dateOnly": {
                    "type": "boolean",
                    "default": false,
                    "title": "Applies to key only"
                  }
                },
                "required": ["key"]
              },
              "prev": { "type": "object", "properties": {}, "required": [] },
              "data": {
                "type": "object",
                "properties": {
                  "dateOnly": {
                    "type": "boolean",
                    "title": "Applies to all keys",
                    "description": "dateOnly (all keys)"
                  }
                }
              }
            },
            "required": ["in", "prev"]
          },
          "aggregateMap": {
            "nodes": {
              "OUTPUT": {
                "type": "OBJECT-BUILDER",
                "config": { "objectSchema": "STATE" },
                "inputs": {
                  "result": {
                    "sourceObject": "newNode3",
                    "sourcePath": "$.result"
                  }
                },
                "position": { "x": 1715.9639200349518, "y": 97.1211577806282 }
              },
              "DATA": {
                "type": "DATA",
                "config": {},
                "position": { "x": 61.291693567874844, "y": 282.1995409086295 }
              },
              "newNode1": {
                "type": "DATE-STRING-PARSER",
                "config": { "name": "Date String Parser" },
                "inputs": {
                  "source": {
                    "sourceObject": "newNode2",
                    "sourcePath": "$.result"
                  }
                },
                "name": "Date String Parser",
                "position": { "x": 541.6634335596507, "y": 67.7478176527643 }
              },
              "newNode2": {
                "type": "OBJECT-DYNAMIC-LOOKUP",
                "config": {
                  "resultType": "String",
                  "name": "Object Dynamic Lookup"
                },
                "inputs": {
                  "input": { "sourceObject": "DATA", "sourcePath": "$.prev" },
                  "key": { "sourceObject": "DATA", "sourcePath": "$.in.key" }
                },
                "name": "Object Dynamic Lookup",
                "position": { "x": 281.69641125121245, "y": 66.30455868089233 }
              },
              "newNode3": {
                "type": "OBJECT-SET-KEY",
                "config": { "name": "Object Set Key", "type": "Date" },
                "inputs": {
                  "input": { "sourceObject": "DATA", "sourcePath": "$.prev" },
                  "key": { "sourceObject": "DATA", "sourcePath": "$.in.key" },
                  "value": {
                    "sourceObject": "newNode6",
                    "sourcePath": "$.output"
                  }
                },
                "name": "Object Set Key",
                "position": { "x": 1469.6269952122782, "y": 264.56388126584244 }
              },
              "newNode4": {
                "type": "DATE-ADDER",
                "config": {},
                "inputs": {
                  "firstSource": {
                    "sourceObject": "newNode1",
                    "sourcePath": "$.dateNumber"
                  },
                  "secondSource": 0
                },
                "position": { "x": 803.0560209666623, "y": 68.97672162948588 }
              },
              "newNode5": {
                "type": "DATE-ADDER",
                "config": {},
                "inputs": {
                  "firstSource": {
                    "sourceObject": "newNode1",
                    "sourcePath": "$.dateNumber"
                  },
                  "secondSource": {
                    "sourceObject": "newNode1",
                    "sourcePath": "$.timeNumber"
                  }
                },
                "position": { "x": 798.8756723690316, "y": 204.2125327604099 }
              },
              "newNode6": {
                "type": "CONDITIONAL-MAPPER",
                "config": { "type": "Date" },
                "inputs": {
                  "trueSource": {
                    "sourceObject": "newNode4",
                    "sourcePath": "$.output"
                  },
                  "falseSource": {
                    "sourceObject": "newNode5",
                    "sourcePath": "$.output"
                  },
                  "booleanSource": {
                    "sourceObject": "newNode8",
                    "sourcePath": "$.output"
                  }
                },
                "position": { "x": 1185.6147743581212, "y": 68.88452382372338 }
              },
              "newNode8": {
                "type": "OR",
                "config": { "name": "Or" },
                "inputs": {
                  "sources": [
                    { "sourceObject": "DATA", "sourcePath": "$.in.dateOnly" },
                    { "sourceObject": "DATA", "sourcePath": "$.data.dateOnly" }
                  ]
                },
                "name": "Or",
                "position": { "x": 879.0772822285661, "y": 378.9150914919006 }
              }
            },
            "outputDefinition": { "outputNode": "OUTPUT" }
          }
        }
      ]
    };
  }
  if(!fileJson.functions.parseDateProps){
    fileJson.functions.parseDateProps = {
      "currentVersion": 1,
      "versions": [
        {
          "versionNumber": 1,
          "output": {
            "type": "object",
            "properties": {
              "result": { "type": "object", "properties": {}, "required": [] }
            },
            "required": ["result"]
          },
          "input": {
            "type": "object",
            "properties": {
              "in": { "type": "object", "properties": {}, "required": [] },
              "data": {
                "type": "object",
                "properties": {
                  "properties": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "key": { "type": "string" },
                        "dateOnly": {
                          "type": "boolean",
                          "title": "Applies to key only"
                        }
                      },
                      "required": ["key"]
                    }
                  },
                  "dateOnly": {
                    "type": "boolean",
                    "title": "Applies to all keys",
                    "description": "dateOnly (all keys)"
                  }
                },
                "required": ["properties"]
              }
            },
            "required": ["in", "data"]
          },
          "aggregateMap": {
            "nodes": {
              "OUTPUT": {
                "type": "OBJECT-BUILDER",
                "config": { "objectSchema": "STATE" },
                "inputs": {
                  "result": {
                    "sourceObject": "newNode1",
                    "sourcePath": "$.reduced"
                  }
                },
                "position": { "x": 787, "y": 82 }
              },
              "DATA": {
                "type": "DATA",
                "config": {},
                "position": { "x": 128, "y": 52 }
              },
              "newNode1": {
                "type": "ARRAY-REDUCE",
                "config": {
                  "type": "Object",
                  "functionId": "parseDatePropsHelper::1",
                  "inputName": "in",
                  "outputName": "result",
                  "inputData": true
                },
                "inputs": {
                  "input": {
                    "sourceObject": "DATA",
                    "sourcePath": "$.data.properties"
                  },
                  "initial": { "sourceObject": "DATA", "sourcePath": "$.in" },
                  "data": {
                    "dateOnly": {
                      "sourceObject": "DATA",
                      "sourcePath": "$.data.dateOnly"
                    }
                  }
                },
                "position": { "x": 412, "y": 52 }
              }
            },
            "outputDefinition": { "outputNode": "OUTPUT" }
          }
        }
      ]
    };
  }
  fileJson.aggregates = aggregates;
  return fileJson;
};

export const migrateGeoGraphyUiType = (value: AggrPropertyRow): AggrPropertyRow => {
  let propertyValue = value;
  const { type, uiType } = propertyValue;
  if (type && type === 'Geography' && (!uiType || uiType !== 'JSON')) {
    propertyValue = { ...propertyValue, uiType: 'JSON' };
  }
  return propertyValue;
};
