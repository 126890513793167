import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { KeyboardArrowRightOutlined } from '@material-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfigContext } from '../../../context/ConfigContext';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { AggregateConfig } from '@terragotech/gen5-config-lib';
import _ from 'lodash';
import { getAggregateIndex } from 'utils/navigationUtils';
import { colors } from 'utils/colors';
import { SingleTextInputForm } from '../../../components/FormDialog/SingleTextInputForm';
import { errorMsg, successMsg } from '../../../components/SnackbarUtilsConfigurator';
import { useFormDialog } from '../../../components/FormDialog/FormDialogService';
import { useAggregateAPI } from '../../../context/fakeAPIHooks/useAggregateAPI';
import DeleteIcon from '../../../resources/images/icon_delete.png';
import {
  ADD_MODAL_WIDTH,
  ASSET_SUBMENU,
  CONFIRMATION,
  getUrlFirstParam,
  LEFT_MENU_WIDTH,
  ROOT_RECORDS_ROUTE,
} from '../../../utils/Utils';
import { useConfirmDialog } from 'context/ConfirmContext';
import { SearchBar } from 'views/components/SearchBar';

const MENU_HEIGHT = 82;

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    flexDirection: 'row-reverse',
    marginBottom: -1,
    padding: 0,
    minHeight: 45,
    height: 0,
    marginLeft: 12,
    '&$expanded': {
      minHeight: 45,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '4px 4px',
    },
    '& .MuiIconButton-root': {
      padding: 4,
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
  },
  expandIcon: {
    '&$expanded': {
      transform: 'rotate(90deg)',
    },
  },
  content: {
    marginLeft: 5,
    '&$expanded': {
      margin: '1px 0',
      marginLeft: 5,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: '0 16px',
  },
}))(MuiAccordionDetails);

const LeftMenu = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const location = useLocation();
  const classes = useStyles();
  const { config, getAggregates, getAggregate, setAggregates } = useContext(ConfigContext);
  const formDialog = useFormDialog();
  const AggregateAPI = useAggregateAPI();
  const [expandPosition, setExpandPosition] = useState<number>(-1);
  const selectedAggregate = getUrlFirstParam(location.pathname, ROOT_RECORDS_ROUTE);
  const currentPath = getUrlFirstParam(
    location.pathname,
    `${ROOT_RECORDS_ROUTE}${selectedAggregate}/`
  );
  const { openConfirmation } = useConfirmDialog();

  const goTo = (path: string) => {
    navigate(path);
  };
  const [accordions, setAccordions] = useState<AggregateConfig[]>(getAggregates());

  useEffect(() => {
    setAccordions(getAggregates());
  }, [config, getAggregates]);

  const updateExpandedItem = useCallback(() => {
    const index = accordions.findIndex((o) => o.typeName === selectedAggregate);
    setExpandPosition(index);
  }, [accordions, selectedAggregate]);

  useEffect(() => {
    updateExpandedItem();
  }, [updateExpandedItem]);

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedAccordions = Array.from(accordions);
    const [movedItem] = reorderedAccordions.splice(result.source.index, 1);
    reorderedAccordions.splice(result.destination.index, 0, movedItem);
    setAccordions(reorderedAccordions);
    setAggregates(reorderedAccordions);
  };

  const handleChange = (index: number) => (event: ChangeEvent<{}>, newExpanded: boolean) => {
    setExpandPosition(newExpanded ? index : -1);
  };

  const handleAddAggregate = async () => {
    const aggrName = await formDialog<typeof SingleTextInputForm>(
      (props) => <SingleTextInputForm title="Create new record" {...props} />,
      true,
      true,
        ADD_MODAL_WIDTH
    );
    if (!isValid(aggrName)) return;
    const { error } = await AggregateAPI.addAggregate(aggrName);
    if (error) return;
    setAccordions(getAggregates());
    successMsg(`The record "${aggrName}" has been successfully added`);
  };

  const isValid = (aggrName: string) => {
    if (ifAggregateExists(aggrName)) {
      errorMsg(`An record named "${aggrName}" algready exists`);
      return false;
    }
    return true;
  };

  const ifAggregateExists = (aggrName: string) =>
    accordions.some(
      (e: { typeName: string }) => e.typeName.toUpperCase() === aggrName.toUpperCase()
    );

  const handleMenu = (typeName: string, menuPath: string) => {
    let path = `${ROOT_RECORDS_ROUTE}${typeName}/${menuPath}`;
    const aggrIndex = getAggregateIndex(config, typeName);
    const aggregateInfo = getAggregate(aggrIndex);
    if (menuPath.match(/^(commands|events)$/)) {
      const [initialCommand = ''] = _.keys(aggregateInfo[menuPath as 'commands' | 'events']);
      if (initialCommand !== '') {
        path = `${path}/${initialCommand}`;
      }
    }
    goTo(path);
  };

  const handleAggrDelete = (index: number) => async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const confirmProp = CONFIRMATION.record;
    const status = await openConfirmation(confirmProp);
    if (status === 'confirm') {
      const aggrName = accordions[index].typeName;
      if (aggrName === selectedAggregate) goTo(ROOT_RECORDS_ROUTE);
      const { error } = await AggregateAPI.deleteAggregate(index);
      if (error) return;
      setAccordions((prev) => prev.filter((item, idx) => idx !== index));
      successMsg(`The record "${aggrName}" has been successfully deleted`);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box className={classes.container}>
        <Box className={classes.menuContainer}>
          <Typography className={classes.itemTxt}>Record Types</Typography>
          <IconButton onClick={handleAddAggregate} className={classes.addItem} color="primary">
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Box>
        <SearchBar value={search} onChange={setSearch} />
        <Droppable droppableId="accordions" type="ACCORDION">
          {(provided) => (
            <Box
              //@ts-ignore
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={classes.droppableContainer}
            >
              <Box className={classes.list}>
                {accordions
                .filter((item) => item && item.typeName && item.typeName.toLowerCase().includes(search.toLowerCase()))
                .map((accordion) => {
                  const originalIndex = accordions.findIndex(a => a.typeName === accordion.typeName);
                  return (
                    <Draggable
                      key={accordion.typeName}
                      draggableId={accordion.typeName}
                      index={originalIndex}
                    >
                      {(provided) => (
                        <Accordion
                          key={accordion.typeName}
                          expanded={expandPosition === originalIndex}
                          onChange={handleChange(originalIndex)}
                        >
                          <AccordionSummary
                            expandIcon={<KeyboardArrowRightOutlined />}
                            onClick={() => goTo(`${ROOT_RECORDS_ROUTE}${accordion.typeName}`)}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              ...styles.summary,
                            }}
                          >
                            <Box {...provided.dragHandleProps}>
                              <FontAwesomeIcon icon={faBars} className={classes.barIcon} />
                            </Box>
                            <Typography className={classes.typeNameText}>
                              {accordion.typeName}
                            </Typography>
                            <img
                              src={DeleteIcon}
                              alt="Delete Asset"
                              className={classes.deleteIcon}
                              onClick={handleAggrDelete(originalIndex)}
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box>
                              {ASSET_SUBMENU.map((menu) => {
                                const selected = menu.path === currentPath;
                                return (
                                  <Button
                                    key={menu.label}
                                    color="primary"
                                    classes={{ root: classes.menuBtn, label: classes.menu }}
                                    onClick={() => handleMenu(accordion.typeName, menu.path)}
                                    className={selected ? classes.selected : ''}
                                  >
                                    {menu.label}
                                    {menu.showCount && (
                                      <Typography style={styles.labelLength}>
                                        {_.keys(_.get(accordion, menu.path))?.length || 0}
                                      </Typography>
                                    )}
                                  </Button>
                                );
                              })}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </Draggable>
                  );
                })}
              </Box>
            </Box>
          )}
        </Droppable>
      </Box>
    </DragDropContext>
  );
};
const styles = {
  summary: {
    left: 'auto !important',
    top: 'auto !important',
  },
  labelLength: {
    position: 'absolute' as const,
    right: 5,
    fontSize: 13,
    fontWeight: 400,
  },
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: LEFT_MENU_WIDTH,
      borderRight: `1px solid ${colors.black10}`,
      backgroundColor: colors.white,
      position: 'relative',
    },
    itemTxt: {
      fontSize: 16,
      fontWeight: 500,
      color: colors.black,
    },
    addItem: {
      fontSize: 20,
      cursor: 'pointer',
      '.MuiIconButton-root': {
        padding: 8,
      },
    },
    deleteIcon: {
      right: 4,
      position: 'absolute',
    },
    menuContainer: {
      height: 45,
      borderBottom: `1px solid ${colors.black10}`,
      padding: '0 11px 0 25px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    list: {
      padding: '10px 25px',
      paddingRight: 11,
    },
    menuBtn: {
      width: 193,
      marginLeft: 5,
      justifyContent: 'flex-start',
      paddingLeft: 25,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        '& .MuiButton-label': {
          color: theme.palette.primary.main,
        },
      },
    },
    selected: {
      backgroundColor: theme.palette.primary.light,
      '& .MuiButton-label': {
        color: theme.palette.primary.main,
      },
    },
    menu: {
      textAlign: 'left',
      marginLeft: 10,
      fontSize: 14,
      fontWeight: 400,
      fontStyle: 'normal',
      color: colors.black54,
    },
    barIcon: {
      position: 'absolute',
      left: -13,
      top: 15,
      width: 15,
      height: 15,
      color: colors.black54,
    },
    typeNameText: {
      fontSize: 15,
      color: colors.black,
    },
    droppableContainer: {
      height: `calc(100% - ${MENU_HEIGHT}px)`,
      overflow: 'scroll',
    },
  })
);

export default LeftMenu;
