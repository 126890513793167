import { useContext, useState } from 'react';
import { Box, IconButton, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeView from '@material-ui/lab/TreeView';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { colors } from 'utils/colors';
import { getExpandedURLArr } from 'utils/navigationUtils';
import { TreeViewNode } from 'components/TreeViewNode';
import { errorMsg, successMsg } from 'components/SnackbarUtilsConfigurator';
import { ConfigContext } from '../../../../context/ConfigContext';
import {
  CONFIRMATION,
  ROOT_UI_ROUTE,
  actions,
  configType,
  ADD_MODAL_WIDTH,
  LEFT_MENU_UI_NODE,
} from '../../../../utils/Utils';
import { useUICustomizationAPI } from 'context/fakeAPIHooks/useUICustomizationAPI';
import { useCommonStyles } from './LeftMenuStyles';
import { useFormDialog } from 'components/FormDialog/FormDialogService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { CreateAggregateUICustomizationDialog } from 'components/FormDialog/CreateAggregateUICustomizationDialog';
import { useConfirmDialog } from 'context/ConfirmContext';
import { SearchBar } from 'views/components/SearchBar';

interface Props {
  keyValue: string;
  title: string;
}

type UIType = 'mobileUIConfig' | 'webUIConfig';

const TreeViewLeftMenu = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const keyPath = props.keyValue;
  const { config, configDetails, getLinkUnLinkStatus } = useContext(ConfigContext);
  const [expandedNodes, setExpandedNodes] = useState<string[] | undefined>(
    getExpandedURLArr(location.pathname)
  );
  const [search, setSearch] = useState('');

  const mobileConfig = config.mobileUIConfig.aggregateUICustomizations;
  const webConfig = config.webUIConfig.aggregateUICustomizations;
  const formDialog = useFormDialog();
  const { openConfirmation } = useConfirmDialog();

  const UICustomizationAPI = useUICustomizationAPI();
  if (config === null) return null;

  const handleAddAggregate = async (type: UIType, name: string) => {
    const { error } = await UICustomizationAPI.addNewAggregateUICustomization(type, name);
    return error;
  };
  const handleDeleteAggregate = async (type: UIType, name: string) => {
    const { error } = await UICustomizationAPI.deleteAggregateUICustomization(type, name);
    return error;
  };

  const handleAddAggregateUICustomization = async () => {
    const aggrUIName = await formDialog<typeof CreateAggregateUICustomizationDialog>(
      (props) => <CreateAggregateUICustomizationDialog configType="mobileUIConfig" {...props} />,
      true,
      true,
      ADD_MODAL_WIDTH
    );
    if (!aggrUIName) return errorMsg(`No aggregate selected`);
    const webError = await handleAddAggregate('webUIConfig', aggrUIName);
    const mobileError = await handleAddAggregate('mobileUIConfig', aggrUIName);
    if (mobileError || webError) return;
    successMsg(`The aggregate "${aggrUIName}" has been successfully added`);
  };

  const handleAggrDelete = (aggrUIName: string) => async () => {
    const data = CONFIRMATION.recordsUI({ name: aggrUIName });
    const status = await openConfirmation(data);
    if (status === 'confirm') {
      const webError = await handleDeleteAggregate('webUIConfig', aggrUIName);
      const mobileError = await handleDeleteAggregate('mobileUIConfig', aggrUIName);

      if (webError || mobileError) return;
      successMsg(`The aggregate UI customisation "${aggrUIName}" has been successfully deleted`);
    }
  };

  const fetchLinkUnlinkStatus = (command: string, nodeKey: string) => {
    const commandsWithKey = `${command}.${nodeKey}`;
    if (configDetails[commandsWithKey] || !mobileConfig || !webConfig) {
      return;
    }

    let defonMob = _.get(mobileConfig, [command, nodeKey]);
    let defonWeb = _.get(webConfig, [command, nodeKey]);
    if (nodeKey === actions.EDITOR_ACTIONS) {
      defonWeb = _.get(webConfig, [command, actions.TABLE_ACTIONS]);
    }
    if (nodeKey === 'mapLabelProperties') {
      const mobileOther = _.get(mobileConfig, command);
      const webOther = _.get(webConfig, command);
      defonMob = {
        data: defonMob,
        showEmptyMapLabels: mobileOther?.showEmptyMapLabels,
        showMapLabelTitles: mobileOther?.showMapLabelTitles,
      };
      defonWeb = {
        data: defonWeb,
        showEmptyMapLabels: webOther?.showEmptyMapLabels,
        showMapLabelTitles: webOther?.showMapLabelTitles,
      };
    }
    getLinkUnLinkStatus(defonWeb, defonMob, commandsWithKey);
  };

  const commandConfigs = _.chain(mobileConfig).entries().orderBy().value();
  return (
    <Box className={[commonClasses.container, classes.container].join(' ')}>
      <Box className={`${commonClasses.menuContainer} ${classes.menuContainer}`}>
        <Typography className={commonClasses.itemTxt}>{props.title}</Typography>
        <Typography className={classes.addItem} color="primary">
          <IconButton onClick={handleAddAggregateUICustomization} color="primary">
            <FontAwesomeIcon icon={faPlus} className={classes.addItem} />
          </IconButton>
        </Typography>
      </Box>
      <SearchBar value={search} onChange={setSearch} />
      <Box className={commonClasses.listBody}>
        <Box className={commonClasses.list}>
          {!_.isEmpty(mobileConfig) ? (
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              onNodeSelect={(_: object, nodeId: string) => {
                navigate(nodeId);
              }}
              selected={location.pathname}
              onNodeToggle={(_: object, nodeIds: string[] | undefined) => setExpandedNodes(nodeIds)}
              expanded={expandedNodes}
            >
              {commandConfigs
              .filter(([commandName]) => commandName.toLowerCase().includes(search.toLowerCase()))
              .map(([commandName, value]) => {
                mobileConfig &&
                  _.keys(mobileConfig).forEach((commands) => {
                    LEFT_MENU_UI_NODE.forEach((node) => {
                      fetchLinkUnlinkStatus(commands, node);
                    });
                  });
                return (
                  <TreeViewNode
                    label={commandName}
                    headerName={commandName}
                    key={commandName}
                    nodeId={`${ROOT_UI_ROUTE}${keyPath}/${commandName}`}
                    onDelete={handleAggrDelete(commandName)}
                    isParent
                  >
                    {_.map(LEFT_MENU_UI_NODE, (treeNode) => (
                      <TreeViewNode
                        label={_.startCase(treeNode)}
                        key={treeNode}
                        nodeId={`${ROOT_UI_ROUTE}${keyPath}/${commandName}/${treeNode}`}
                        isLinked={
                          configDetails[`${commandName}.${treeNode}`]?.type === configType.ALL.type
                        }
                      />
                    ))}
                  </TreeViewNode>
                );
              })}
            </TreeView>
          ) : (
            <Typography className={classes.emptyContent}>No aggregate UI Customisations</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: 300,
    },
    menuContainer: {
      height: 45,
      padding: '11px 12px 10px 25px',
    },
    addItem: {
      fontSize: 20,
    },
    deleteIcon: {
      right: 0,
      position: 'absolute',
    },
    list: {
      padding: '0 25px',
      paddingBottom: 10,
      paddingRight: 13,
    },
    emptyContent: { color: colors.black54, fontSize: 14, textAlign: 'center' },
  })
);

export default TreeViewLeftMenu;
