import { V2ListComponent } from '@terragotech/page-renderer';
import _ from 'lodash';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { NestedPageControlProps } from '../PageTypes';
import { Label, LabelSubText, StandardInputFrame } from './Common';
import { CustomPageBadges } from './CustomPageBadges';

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;
const ItemContainer = styled.div`
  justify-content: space-around;
`;

const ListControl: FunctionComponent<NestedPageControlProps<V2ListComponent>> = (props) => {
  const { controlDefinition, pasted, isPageEditor } = props;
  const capitalizedControlType = _.upperFirst(controlDefinition.type);

  return (
    <StandardInputFrame>
      <ItemContainer>
        <Label
          text={controlDefinition.label}
          conditional={Boolean(controlDefinition.displayOptions)}
          isPageEditor={isPageEditor}
        />
        {isPageEditor && (
          <LabelSubText
            isDisplayOptionsEnabled={Boolean(controlDefinition.displayOptions)}
            isList={true}
          >
            {capitalizedControlType}
          </LabelSubText>
        )}
        <CustomPageBadges controlDefinition={controlDefinition} />
        {pasted && <PastedMark>Pasted</PastedMark>}
      </ItemContainer>
    </StandardInputFrame>
  );
};

export default ListControl;
