import React, { useState } from 'react';
import { V2BarChartOptions } from '@terragotech/page-renderer';
import { TGSelect } from '../../../views/components/formElements';
import { ChartEditSectionProps } from './common';

const groupModeOptions: Record<NonNullable<V2BarChartOptions['groupMode']>, string> = {
  grouped: 'Grouped',
  stacked: 'Stacked',
};
const layoutOptions: Record<NonNullable<V2BarChartOptions['layout']>, string> = {
  horizontal: 'Horizontal',
  vertical: 'Vertical',
};

export const BarChartEditSection: React.FC<ChartEditSectionProps<'bar'>> = ({
  chartOptions,
  setChartOptions,
  containerCss,
  containerStyle,
  labelCss,
  labelStyle,
}) => {
  const [groupMode, setGroupMode] = useState(chartOptions?.groupMode);
  const [layout, setLayout] = useState(chartOptions?.layout);

  return (
    <>
      <TGSelect
        id="groupMode"
        label="Group Mode"
        value={groupModeOptions[groupMode!]}
        options={groupModeOptions}
        onChange={(value) => {
          setGroupMode(value as V2BarChartOptions['groupMode']);
          setChartOptions((o) => ({ ...o, groupMode: value as V2BarChartOptions['groupMode'] }));
        }}
        containerStyle={containerStyle}
        containerCss={containerCss}
        labelStyle={labelStyle}
        labelCss={labelCss}
      />
      <TGSelect
        id="layout"
        label="Layout"
        value={layoutOptions[layout!]}
        options={layoutOptions}
        onChange={(value) => {
          setLayout(value as V2BarChartOptions['layout']);
          setChartOptions((o) => ({ ...o, layout: value as V2BarChartOptions['layout'] }));
        }}
        containerStyle={containerStyle}
        containerCss={containerCss}
        labelStyle={labelStyle}
        labelCss={labelCss}
      />
    </>
  );
};

export default BarChartEditSection;
