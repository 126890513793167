import React, { useCallback, useState } from 'react';
import {
  Theme,
  createStyles,
  makeStyles,
  Box,
  Typography,
  InputAdornment,
  withStyles,
  Divider,
  alpha,
  IconButton,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { colors } from 'utils/colors';
import MuiTextField from '@material-ui/core/TextField';
import _ from 'lodash';

interface PagePaletteProps {
  isGroup: boolean;
  handlePaletteClose: () => void;
  handleOptionClick: (item: string) => void;
}
const SEARCH_AND_HEADER_HEIGHT = 90;
const TextField = withStyles({
  root: {
    '& .MuiInputBase-root': {
      height: '35px',
      border: 'none',
      padding: 14,
    },
    '& input::placeholder': {
      fontSize: '15px',
      fontWeight: 400,
      color: colors.black30,
    },
  },
})(MuiTextField);
export const PagePalette: React.FC<PagePaletteProps> = ({
  isGroup,
  handlePaletteClose,
  handleOptionClick,
}) => {
  const classes = useStyles();
  const [palette, setPalette] = useState(paletteItems);

  const handleSearch = useCallback((searchText: String) => {
    if (searchText.trim() != '') {
      const filteredData = _.filter(paletteItems, (item) => {
        return item.label.toLowerCase().startsWith(searchText.toLowerCase());
      });
      setPalette(filteredData);
      return;
    }
    setPalette(paletteItems);
  }, []);

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.header}>
        <Box className={classes.headerContainer}>
          <Typography className={classes.headerTitle}>Form Palette</Typography>
          <IconButton
            onClick={() => {
              handlePaletteClose();
            }}
          >
            <FontAwesomeIcon icon={faXmark} className={classes.icon} />
          </IconButton>
        </Box>
        <Box className={classes.searchContainer}>
          <TextField
            fullWidth
            variant="standard"
            margin="normal"
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faMagnifyingGlass} className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
            className={classes.inputContainer}
            type="text"
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.paletteOptions}>
        {palette
          .filter((x) => !isGroup || x.type !== 'group')
          .map((item, index) => (
            <Typography
              key={index}
              onClick={() => handleOptionClick(item.type)}
              className={classes.options}
            >
              {item.label}
            </Typography>
          ))}
      </Box>
    </Box>
  );
};

const paletteItems = [
  { type: 'text', label: 'Text', icon: '' },
  { type: 'number', label: 'Number', icon: '' },
  { type: 'date', label: 'Date', icon: '' },
  { type: 'time', label: 'Time', icon: '' },
  { type: 'datetime', label: 'DateTime', icon: '' },
  { type: 'image', label: 'Image', icon: '' },
  { type: 'file', label: 'File', icon: '' },
  { type: 'chart', label: 'Chart', icon: '' },
  { type: 'group', label: 'Group', icon: '' },
  { type: 'aggregateMap', label: 'Map', icon: '' },
  { type: 'streetView', label: 'Street View (Web Detail Only)', icon: '' },
  { type: 'list', label: 'List', icon: '' },
];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      height: '100%',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignSelf: 'normal',
      paddingBottom: 3,
      paddingLeft: 4,
    },
    icon: {
      height: 26,
      width: 26,
      color: colors.black25,
    },
    searchIcon: {
      color: colors.black25,
    },
    divider: {
      marginTop: 15,
    },
    paletteOptions: {
      height: `calc(100% - ${SEARCH_AND_HEADER_HEIGHT}px)`,
      overflowY: 'auto',
      padding: '7px 0',
    },
    header: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '4px 24px',
      paddingRight: 6,
      cursor: 'pointer',
    },
    headerTitle: {
      fontSize: '18px',
      fontWeight: 500,
      color: colors.black,
    },
    options: {
      fontSize: '15px',
      fontWeight: 400,
      padding: '8px 19px',
      color: colors.black,
      marginInline: 10,
      '&:hover': {
        cursor: 'pointer',
        borderRadius: 4,
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      },
    },
    searchContainer: {
      paddingRight: 18,
      width: '100%',
    },
    inputContainer: {
      display: 'block',
      width: '100%',
      margin: 'auto',
      border: 'none',
      borderRadius: 3,
      backgroundColor: colors.gray10,
      '& .MuiInputBase-root': {
        color: colors.black,
      },
    },
  })
);
