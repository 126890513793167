import { Box, Grid, Theme, Typography, makeStyles } from '@material-ui/core';
import MDEditor from '@uiw/react-md-editor';
import useRouteBlocker from 'common/useBlocker';
import _ from 'lodash';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useBlocker } from 'react-router-dom';
import { COMMANDS } from 'utils/Utils';
import { colors } from 'utils/colors';
import AccordionView from './AccordionView';

const EMPTY_DESCRIPTION_TEXT = 'No description to display';
const EDITOR_VERTICAL_PADDING = 32;

const TGEditor = ({
  description,
  onToggle,
  title,
  name,
  isAccordion = false,
}: {
  description: string;
  onToggle: (isEditMode: boolean, comment: string) => Promise<void>;
  title: string;
  name: string;
  isAccordion?: boolean;
}) => {
  const classes = useStyles();
  const editorRef = useRef<HTMLDivElement>(null);
  const [editMode, setEditMode] = useState(false);
  const [comment, setComment] = useState(description);
  const [editorHeight, setEditorHeight] = useState(500);

  useEffect(() => {
    setEditMode(false);
  }, [name]);

  useEffect(() => {
    setComment(description);
  }, [description]);

  const updateEditorHeight = () => {
    if (editorRef.current) {
      setEditorHeight(editorRef.current.clientHeight - EDITOR_VERTICAL_PADDING);
    }
  };

  useLayoutEffect(() => {
    updateEditorHeight();
    addEventListener('resize', updateEditorHeight);
    return () => {
      removeEventListener('resize', updateEditorHeight);
    };
  }, []);

  const handleToggle = (e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    e?.stopPropagation();
    onToggle(editMode, comment);
    setEditMode((e) => !e);
  };

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && description !== comment
  );

  const onDiscard = () => {
    setEditMode(false);
    setComment(description);
  };

  useRouteBlocker({ blocker, onSave: handleToggle, onDiscard });
  const descriptions = !_.isEmpty(comment) ? comment : EMPTY_DESCRIPTION_TEXT;

  return (
    <Box className={classes.container}>
      {isAccordion ? (
        <AccordionView
          header={<Header {...{ handleToggle, editMode, title }} />}
          body={
            <Body
              {...{
                editMode,
                comment,
                editorHeight,
                setComment,
                descriptions,
                editorRef,
              }}
            />
          }
        />
      ) : (
        <Box className={classes.editor}>
          <Header {...{ handleToggle, editMode, title }} />
          <Body
            {...{
              editMode,
              comment,
              editorHeight,
              setComment,
              descriptions,
              editorRef,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

const Header = ({
  handleToggle,
  editMode,
  title,
}: {
  handleToggle: (e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void;
  editMode: boolean;
  title: string;
}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.headerContainer}>
      <Grid item sm={10}>
        <Typography className={classes.headerText}>{title}</Typography>
      </Grid>
      <Grid item sm={2} className={classes.button}>
        <Typography
          color="primary"
          className={`${classes.editBtn} ${editMode ? classes.editMode : ''}`}
          onClick={handleToggle}
        >
          {editMode ? 'Save' : 'Edit'}
        </Typography>
      </Grid>
    </Grid>
  );
};

const Body = ({
  editMode,
  comment,
  editorHeight,
  setComment,
  descriptions,
  editorRef,
}: {
  editMode: boolean;
  comment: string;
  editorHeight: number;
  setComment: Dispatch<SetStateAction<string>>;
  descriptions: string;
  editorRef: RefObject<HTMLDivElement>;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.editorContainer} ref={editorRef}>
      {editMode && (
        <MDEditor
          value={comment}
          height={editorHeight}
          preview="edit"
          commands={COMMANDS}
          onChange={(val?: string): void => setComment(val || '')}
        />
      )}
      {!editMode && (
        <MDEditor.Markdown
          source={descriptions}
          className={
            !_.isEmpty(comment)
              ? classes.editorContent
              : [classes.editorContent, classes.noDescription].join(' ')
          }
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 25,
    width: '100%',
  },
  headerText: {
    fontSize: 18,
    fontWeight: 500,
    color: colors.black,
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editBtn: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: 0,
    width: 30,
    fontSize: 15,
    fontWeight: 500,
    textAlign: 'center',
    cursor: 'pointer',
  },
  editMode: {
    width: 42,
  },
  editorContainer: {
    padding: '16px 0',
    width: '100%',
    height: '100%',
    '& ::selection': {
      '-webkit-text-fill-color': colors.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  editorContent: {
    fontSize: 16,
    fontWeight: 400,
    color: colors.black,
    lineHeight: '16px',
    fontFamily: 'Inter Variable',
  },
  noDescription: {
    color: colors.black57,
    textAlign: 'left',
    letterSpacing: '0em',
  },
  editor: {
    padding: '0 17px',
    height: '100%',
  },
}));

export default TGEditor;
