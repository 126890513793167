import { FormControl } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { TGTextInput } from '../../views/components/formElements';
import { ScheduleActionRow } from '@terragotech/gen5-config-lib';

interface TimerTimeSelectProps {
  row: ScheduleActionRow;
  onChange: (value: string | undefined) => void;
  startOrEnd: boolean;
}

export const TimerTimeSelect = ({ row, onChange, startOrEnd }: TimerTimeSelectProps) => {
  const initialTime = startOrEnd ? row.startTime : row.endTime;
  const defaultTime = startOrEnd ? '00:00' : '23:59';
  const [time, setTime] = useState(!initialTime || initialTime === '' ? defaultTime : initialTime);

  useEffect(() => {
    if (startOrEnd || row.repeat !== 'Daily') {
      if ((startOrEnd && row.startTime !== time) || (!startOrEnd && row.endTime !== time)) {
        onChange(time);
      }
    }
    if (row.endTime && row.repeat === 'Daily') {
      if (!startOrEnd) {
        if (row.endTime !== '-') {
          onChange('-');
        }
      }
    }
  }, [startOrEnd, time, setTime, row, onChange]);

  const handleChange = (value: string, setter: (value: string) => void) => {
    setter(value);
    onChange(value);
  };

  const styles = {
    container: {
      display: 'flex',
      'flex-direction': 'row',
      minWidth: 120,
      marginTop: 10,
    },
    timeContainer: {
      flex: '0 0 100%',
      display: 'flex',
      'flex-direction': 'column',
      marginBottom: 5,
    },
  };

  return (
    <FormControl variant="outlined">
      <div style={styles.container}>
        <div style={styles.timeContainer}>
          <TGTextInput value={time} onChange={(value) => handleChange(value, setTime)} />
        </div>
      </div>
    </FormControl>
  );
};
