import { useEffect, useState } from 'react';
import { ScheduleActionRow } from '@terragotech/gen5-config-lib';
import { TGTextInput } from 'views/components/formElements';
import { FormControl } from '@material-ui/core';

interface TimerRepeatCustomSelectProps {
  row: ScheduleActionRow;
  onChange: (value: string) => void;
}

export const TimerRepeatCustomSelect = ({ row, onChange }: TimerRepeatCustomSelectProps) => {
  const hourMinRegex = /^(\d\d)H (\d\d)M$/;
  const match = row.repeat?.match(hourMinRegex);
  const initialTime = match ? [match[1], match[2]] : ['01', '00'];
  const [hour, setHour] = useState(initialTime[0]);
  const [minute, setMinute] = useState(initialTime[1]);

  useEffect(() => {
    if (row.repeat !== 'Daily' && row.repeat !== `${hour}H ${minute}M`) {
      onChange(`${hour}H ${minute}M`);
    }
  }, [hour, setHour, minute, setMinute, row, onChange]);

  const handleChange = (value: string, setter: (value: string) => void) => {
    setter(value);
    if (setter === setHour) {
      onChange(`${value}H ${minute}M`);
    } else {
      onChange(`${hour}H ${value}M`);
    }
  };

  const styles = {
    customRepeat: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 8,
    },
    customSelectLabel: {
      marginRight: 10,
      marginLeft: 10,
    },
  };

  return (
    <div style={styles.customRepeat}>
      <FormControl>
        <TGTextInput value={hour} onChange={(value) => handleChange(value, setHour)} />
      </FormControl>
      <div style={styles.customSelectLabel}>Hours</div>
      <FormControl>
        <TGTextInput value={minute} onChange={(value) => handleChange(value, setMinute)} />
      </FormControl>
      <div style={styles.customSelectLabel}>Mins</div>
    </div>
  );
};
