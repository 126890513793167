import React, { ChangeEvent } from 'react';
import _, { size } from 'lodash';
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { TextInput } from './TGTextInput';
import { colors } from 'utils/colors';
import Placeholder from 'common/PlaceHolder';
import { Variant } from 'components/FormElements';

interface Props {
  id: string;
  value: string | string[];
  label?: string;
  placeholder?: string;
  variant?: Variant;
  options?: Array<string | number> | Object;
  onChange?: (value: string) => void;
  onChangeMultiple?: (value: string[]) => void;
  containerStyle?: string | object;
  containerCss?: React.CSSProperties;
  labelStyle?: string | object;
  labelCss?: React.CSSProperties;
  style?: React.CSSProperties;
  type?: string | undefined;
  multiple?: boolean;
  disabled?: boolean;
  canShowEmpty?: boolean;
  selectedValue?: string;
}

export const TGSelect = ({
  id,
  value,
  options,
  onChange,
  onChangeMultiple,
  label,
  placeholder = '',
  containerStyle,
  containerCss,
  labelStyle,
  labelCss,
  type,
  style,
  variant,
  multiple,
  disabled,
  canShowEmpty = false,
  selectedValue,
}: Props) => {
  const classes = useStyles();

  const handleClearSelection = () => {
    if (multiple && onChangeMultiple) {
      onChangeMultiple([]);
    } else if (onChange) {
      onChange('');
    }
  };

  return (
    <FormControl
      className={`${classes.formControl} ${containerStyle ?? ''}`}
      style={containerCss}
      variant={variant ? variant : 'outlined'}
    >
      {size(label) > 0 && (
        <InputLabel shrink htmlFor={id} className={`${classes.inputLabel} ${labelStyle ?? ''}`} style={labelCss}>
          {label}
        </InputLabel>
      )}
      {!multiple && (
        <Select
          id={id}
          displayEmpty
          disabled={disabled}
          value={selectedValue || value}
          renderValue={() => {
            const displayValue =
              !_.isUndefined(value) && !_.isEmpty(value) ? value : `Select ${type || 'type'}`;
            return _.isEmpty(value) ? (
              <Placeholder>{displayValue}</Placeholder>
            ) : (
              <>{displayValue}</>
            );
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          onChange={(
            e: ChangeEvent<{
              name?: string | undefined;
              value: unknown;
            }>
          ) => {
            if (e?.target?.value === 'clear') {
              handleClearSelection();
            } else if (onChange) {
              onChange((e?.target?.value as string) || '');
            }
          }}
          input={<TextInput placeholder={placeholder} multiline={multiple} />}
          className={classes.selectTxt}
          style={style}
        >
          {(!_.isEmpty(options) &&
            (_.isArray(options)
              ? [
                  canShowEmpty && (
                    <MenuItem key="" value="" className={classes.menuItem}>
                      (None Selected)
                    </MenuItem>
                  ),
                  ..._.map(options, (option) =>
                    typeof option === 'object' ? (
                      <MenuItem
                        key={option.name as string}
                        value={option.name}
                        className={classes.menuItem}
                      >
                        {option.label}
                      </MenuItem>
                    ) : (
                      <MenuItem key={option as string} value={option} className={classes.menuItem}>
                        {typeof option === 'number' ? `Version ${option}` : option}
                      </MenuItem>
                    )
                  ),
                ]
              : [
                  canShowEmpty && (
                    <MenuItem key="" value="" className={classes.menuItem}>
                      (None Selected)
                    </MenuItem>
                  ),
                  ..._.map(options, (value, key) => (
                    <MenuItem key={key} value={key} className={classes.menuItem}>
                      {typeof value === 'function' ? value(key) : value}
                    </MenuItem>
                  )),
                ])) || (
            <MenuItem value="" disabled>
              <em>None Available</em>
            </MenuItem>
          )}
        </Select>
      )}
      {multiple && (
        <Select
          id={id}
          displayEmpty
          disabled={disabled}
          value={selectedValue || value}
          multiple={multiple}
          renderValue={(value) => {
            const valueArray = Array.isArray(value) ? value : [value];
            const optionsMap: Record<string, string> = !_.isEmpty(options)
              ? _.isArray(options)
                ? options.reduce(
                    (map, o) =>
                      typeof o === 'object' ? { ...map, [o.name]: o.label } : { ...map, [o]: o },
                    {}
                  )
                : options
              : {};
            const selected = valueArray.map((s) => optionsMap[s] ?? s);
            const displayValue =
              !_.isUndefined(selected) && !_.isEmpty(selected)
                ? selected.join(', ')
                : `Select ${type || 'type'}`;
            return _.isEmpty(selected) ? (
              <Placeholder>{displayValue}</Placeholder>
            ) : (
              <>{displayValue}</>
            );
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          onChange={(e: ChangeEvent<{ value: unknown }>) => {
            if ((e.target.value as string[]).includes('clear')) {
              handleClearSelection();
            } else if (onChangeMultiple) {
              onChangeMultiple(e.target.value as string[]);
            }
          }}
          input={<TextInput placeholder={placeholder} multiline={multiple} />}
          className={classes.selectTxt}
          style={style}
        >
          {(!_.isEmpty(options) &&
            (_.isArray(options)
              ? [
                  canShowEmpty && (
                    <MenuItem key="" value="clear" className={classes.menuItemMultiple}>
                      (None Selected)
                    </MenuItem>
                  ),
                  ..._.map(options, (option) =>
                    typeof option === 'object' ? (
                      <MenuItem
                        key={option.name as string}
                        value={option.name}
                        className={classes.menuItemMultiple}
                      >
                        <Checkbox checked={value.includes(option.name as string)} />
                        {option.label}
                      </MenuItem>
                    ) : (
                      <MenuItem
                        key={option as string}
                        value={option}
                        className={classes.menuItemMultiple}
                      >
                        <Checkbox checked={value.includes(option as string)} />
                        {typeof option === 'number' ? `Version ${option}` : option}
                      </MenuItem>
                    )
                  ),
                ]
              : [
                  canShowEmpty && (
                    <MenuItem key="" value="clear" className={classes.menuItemMultiple}>
                      (None Selected)
                    </MenuItem>
                  ),
                  ..._.map(options, (optionValue, key) => (
                    <MenuItem key={key} value={key} className={classes.menuItemMultiple}>
                      <Checkbox checked={value.includes(key)} />
                      {typeof optionValue === 'function' ? optionValue(key) : optionValue}
                    </MenuItem>
                  )),
                ])) || (
            <MenuItem value="" disabled>
              <em>None Available</em>
            </MenuItem>
          )}
        </Select>
      )}
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black,
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, -3px) scale(1)',
      },
    },
    inputFieldContainer: {
      display: 'flex',
      width: '100%',
      marginBottom: 20,
    },
    formControl: {
      width: '100%',
      '& .MuiSelect-root': {
        fontSize: 15,
        color: colors.black,
      },
    },
    menuItem: {
      color: colors.black,
      fontSize: 15,
      width: '98%',
      margin: 'auto',
      borderRadius: 5,
    },
    menuItemMultiple: {
      color: colors.black,
      fontSize: 15,
      width: '98%',
      margin: 'auto',
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
    },
    selectTxt: {
      '& .MuiSelect-select:focus': {
        borderRadius: 5,
      },
      '& .MuiSelect-select.MuiSelect-select': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  })
);
