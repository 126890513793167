import React from 'react';
import { Box } from '@material-ui/core';
import { useBlocker, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import { EventMapDiagram } from '../../../map-editor/src';
import { FullScreen } from '../../../components/FullScreen';
import { UseEventMapDiagramOutput } from '../../../map-editor/src/Hooks/useDataMapDiagram';
import useRouteBlocker from 'common/useBlocker';

interface EventMapperProps {
  eventMapDiagramData: UseEventMapDiagramOutput;
  save: () => void;
  onDiscard: () => Promise<void>;
}

export const EventMapper: React.FC = () => {
  const props = useOutletContext() as EventMapperProps;
  const eventMapDiagramData = props?.eventMapDiagramData;
  const {
    model,
    engine,
    checkModelHasChanged,
    hasSetPosition,
    setInitialDataMap,
  } = eventMapDiagramData;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && checkModelHasChanged()
  );

  useRouteBlocker({ blocker, onSave: props.save, onDiscard: props?.onDiscard });

  return (
    <EventMapperContainer>
      <FullScreen>
        <EventMapDiagram
          model={model}
          engine={engine}
          hasSetPosition={hasSetPosition}
          mapScenario={'EVENT_AGGREGATE_MAP'}
          setInitialDataMap={setInitialDataMap}
        />
      </FullScreen>
    </EventMapperContainer>
  );
};

const EventMapperContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: 100%;
`;
