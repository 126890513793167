import { FormControl } from '@material-ui/core';
import { ScheduleActionRow } from '@terragotech/gen5-config-lib';
import { useEffect, useState } from 'react';
import { TGSelect } from '../../views/components/formElements';

interface DaySelectProps {
  row: ScheduleActionRow;
  onChange: (value: string | undefined) => void;
}

const dayMap: Record<string, string> = {
  Monday: 'M',
  Tuesday: 'Tu',
  Wednesday: 'W',
  Thursday: 'Th',
  Friday: 'F',
  Saturday: 'Sa',
  Sunday: 'Su',
};

export const TimerDaySelect = ({ row, onChange }: DaySelectProps) => {
  const prevDays = row.days
    ? row.days.split(', ')
    : ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const [days, setDays] = useState<string[]>(prevDays);

  useEffect(() => {
    const sortedDays = days.sort(
      (a, b) => Object.keys(dayMap).indexOf(a) - Object.keys(dayMap).indexOf(b)
    );
    const rowDays = sortedDays.map((item) => dayMap[item]).join(', ');
    if (row.days !== rowDays) {
      onChange(rowDays);
    }
  }, [days, setDays, row, onChange]);

  const handleDayChange = (value: string[]) => {
    const sortedDays = value.sort(
      (a, b) => Object.keys(dayMap).indexOf(a) - Object.keys(dayMap).indexOf(b)
    );
    const rowDays = sortedDays.map((item) => dayMap[item]).join(', ');
    setDays(sortedDays);
    onChange(rowDays);
  };

  const styles = {
    formContainer: {
      minWidth: 120,
      marginTop: 10,
    },
  };

  return (
    <FormControl variant="outlined" style={styles.formContainer}>
      <TGSelect
        id="DaySelect"
        value={days.map((item) => Object.keys(dayMap).find((key) => dayMap[key] === item) || item)}
        options={Object.keys(dayMap)}
        onChangeMultiple={handleDayChange}
        type="Days"
        multiple
      />
    </FormControl>
  );
};
