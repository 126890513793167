import React, { useState } from 'react';
import { V2LineChartOptions } from '@terragotech/page-renderer';
import { TGSelect } from '../../../views/components/formElements';
import { ChartEditSectionProps } from './common';

const curveOptions: Record<NonNullable<V2LineChartOptions['curve']>, string> = {
  basis: 'Basis',
  cardinal: 'Cardinal',
  catmullRom: 'Catmull-Rom',
  linear: 'Linear',
  monotoneX: 'Monotone (X-axis)',
  monotoneY: 'Monotone (Y-axis)',
  natural: 'Natural',
  step: 'Step',
  stepAfter: 'Step After',
  stepBefore: 'Step Before',
};
const layersOptions: Record<NonNullable<V2LineChartOptions['layers']>[number], string> = {
  grid: 'Grid',
  markers: 'Markers',
  axes: 'Axes',
  areas: 'Areas',
  crosshair: 'Crosshair',
  lines: 'Lines',
  points: 'Points',
  slices: 'Slices',
  mesh: 'Mesh',
  legends: 'Legends',
};

export const LineChartEditSection: React.FC<ChartEditSectionProps<'line'>> = ({
  chartOptions,
  setChartOptions,
  containerCss,
  containerStyle,
  labelCss,
  labelStyle,
}) => {
  const [curve, setCurve] = useState(chartOptions?.curve);
  const [layers, setLayers] = useState(chartOptions?.layers);

  return (
    <>
      <TGSelect
        id="curve"
        label="Curve"
        value={curveOptions[curve!]}
        options={curveOptions}
        onChange={(value) => {
          setCurve(value as V2LineChartOptions['curve']);
          setChartOptions((o) => ({ ...o, curve: value as V2LineChartOptions['curve'] }));
        }}
        containerStyle={containerStyle}
        containerCss={containerCss}
        labelStyle={labelStyle}
        labelCss={labelCss}
      />
      <TGSelect
        id="layers"
        label="Layers"
        type="layer(s)"
        multiple={true}
        value={layers ?? []}
        options={layersOptions}
        onChangeMultiple={(valueArray) => {
          const value = valueArray.length === 0 ? undefined : valueArray;
          setLayers(value as V2LineChartOptions['layers']);
          setChartOptions((o) => ({ ...o, layers: value as V2LineChartOptions['layers'] }));
        }}
        containerStyle={containerStyle}
        containerCss={containerCss}
        labelStyle={labelStyle}
        labelCss={labelCss}
      />
    </>
  );
};

export default LineChartEditSection;
