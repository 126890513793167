import React, { useContext, useState } from 'react';
import { ConfigContext } from '../../context/ConfigContext';
import { getAggregateIndex } from '../navigationUtils';
import { commandRefObjToList } from '../jsonPartsGenerators';
import { FormControl } from '@material-ui/core';
import { TGSelect } from 'views/components/formElements';
import { CommandReference, Gen5Config, ScheduleActionRow } from '@terragotech/gen5-config-lib';

interface TimerActionSelectProps {
  row: ScheduleActionRow;
  onChange: (value: string | undefined) => void;
}

export const TimerActionSelect: React.FC<TimerActionSelectProps> = ({ row, onChange }) => {
  const [action, setAction] = useState<string>(row.timer || '');
  const { config } = useContext(ConfigContext);

  const getTimerCommands = (config: Gen5Config, indexOfAggr: number) => {
    const commandRefArr = [] as CommandReference[];
    let aggregatesArr = config.aggregates;
    if (indexOfAggr === 0 || indexOfAggr) {
      aggregatesArr = [config.aggregates[indexOfAggr]];
    }
    aggregatesArr.forEach((aggr) => {
      if (aggr?.commands)
        Object.entries(aggr.commands).forEach(([commandName, command]) => {
          command.versions.forEach((version) => {
            if (version.commandType === 'TIMER') {
              commandRefArr.push({ commandName, commandVersion: version.version });
            }
          });
        });
    });
    return commandRefArr;
  };

  const getSelectedAggregateIndex = () => {
    return getAggregateIndex(config, row.recordName);
  };

  const getSelectedAggregateCommands = () => {
    const index = getSelectedAggregateIndex();
    const array = getTimerCommands(config, index);
    const listRefObj = commandRefObjToList(array);
    return listRefObj;
  };

  const handleChange = (value: string | undefined) => {
    setAction(value || '');
    onChange(value);
  };

  const styles = {
    formContainer: {
      minWidth: 120,
      marginTop: 10,
    },
  };

  return (
    <FormControl variant="outlined" style={styles.formContainer}>
      <TGSelect
        id="Action"
        value={action}
        options={Object.keys(getSelectedAggregateCommands())}
        onChange={handleChange}
        type="Action"
      />
    </FormControl>
  );
};
