import React, { useState } from 'react';
import { V2ScatterPlotChartOptions } from '@terragotech/page-renderer';
import { TGSelect } from '../../../views/components/formElements';
import { ChartEditSectionProps } from './common';

const layersOptions: Record<NonNullable<V2ScatterPlotChartOptions['layers']>[number], string> = {
  grid: 'Grid',
  axes: 'Axes',
  nodes: 'Nodes',
  markers: 'Markers',
  mesh: 'Mesh',
  legends: 'Legends',
  annotations: 'Annotations',
};

export const ScatterPlotChartEditSection: React.FC<ChartEditSectionProps<'scatterPlot'>> = ({
  chartOptions,
  setChartOptions,
  containerCss,
  containerStyle,
  labelCss,
  labelStyle,
}) => {
  const [layers, setLayers] = useState(chartOptions?.layers);

  return (
    <>
      <TGSelect
        id="layers"
        label="Layers"
        type="layer(s)"
        multiple={true}
        value={layers ?? []}
        options={layersOptions}
        onChangeMultiple={(valueArray) => {
          const value = valueArray.length === 0 ? undefined : valueArray;
          setLayers(value as V2ScatterPlotChartOptions['layers']);
          setChartOptions((o) => ({ ...o, layers: value as V2ScatterPlotChartOptions['layers'] }));
        }}
        containerStyle={containerStyle}
        containerCss={containerCss}
        labelStyle={labelStyle}
        labelCss={labelCss}
      />
    </>
  );
};

export default ScatterPlotChartEditSection;
