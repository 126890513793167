"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateAdvancedSearch = void 0;
function checkForValidity(clause) {
    let needToValidate = clause || '';
    needToValidate = needToValidate.replace(/'.+?'|".+?"/g, ''); // All quoted strings safe
    if (needToValidate.includes('--') || needToValidate.includes('/*') || //Reject comments
        //ban exec,union,drop,delete,select,alter,create,insert
        needToValidate.match(/\W(exec|union|drop|delete|select|alter|create|insert)\W/i) ||
        needToValidate.match(/'|"|;/)) { //Reject semicolons or mismatched quotes
        return false;
    }
    return true;
}
const validateAdvancedSearch = (args, config) => {
    var _a, _b, _c, _d, _e, _f;
    const agg = config.aggregates.find((a) => a.typeName === args.recordType);
    const aggregateResults = args.aggregateResults === 'Yes';
    const selects = aggregateResults ? (args.selectValues || []).concat(...(args.groupBy || [])) : (args.selectValues || []);
    const schema = args.schema === 'Analytic' ? 'analytic' : 'gen5';
    if (!selects.length) {
        throw new Error('Select values not supplied');
    }
    if ((args.selectValues || []).find(v => {
        if (aggregateResults) {
            if (v === 'COUNT(*) AS "count"') {
                return false;
            }
            return !v.match(/^(MIN|MAX|AVG|STDDEV|SUM)\("\w+"\."\w+"\) AS "\w+"$/);
        }
        else {
            return !v.match(/^"\w+"\."\w+"( AS "\w+")?$/);
        }
    })) {
        throw new Error('Invalid select value was included');
    }
    if ((_a = args.orderBy) === null || _a === void 0 ? void 0 : _a.find(v => {
        return !v.match(/^"(\w+)"(\."(\w+)")? (ASC|DESC)$/);
    })) {
        throw new Error('Invalid order by was included');
    }
    if (aggregateResults && ((_b = args.groupBy) === null || _b === void 0 ? void 0 : _b.find(v => {
        return !v.match(/^"\w+"\."\w+"$/);
    }))) {
        throw new Error('Invalid group by was included');
    }
    if (args.schema && !['Final Projection', 'Analytic'].includes(args.schema)) {
        throw new Error('Invalid schema was provided');
    }
    if (!checkForValidity(args.whereClause)) {
        throw new Error('Invalid where clause');
    }
    if (aggregateResults && !checkForValidity(args.havingClause)) {
        throw new Error('Invalid having clause');
    }
    if ((_c = args.joins) === null || _c === void 0 ? void 0 : _c.find(j => {
        return !j.match(/^(INNER|LEFT) JOIN \w+\.\w+ \(\w+\) AS "\w+"$/);
    })) {
        throw new Error('Invalid join clause');
    }
    const joins = (_d = args.joins) === null || _d === void 0 ? void 0 : _d.map(j => {
        const parts = j.match(/^(\w+ JOIN) (\w+)\.(\w+) \((\w+)\) AS ("\w+")$/);
        const sourceType = parts[2] === 'record' ? args.recordType : args.joins.find(j => {
            return j.includes(` AS "${parts[2]}"`);
        }).match(/^\w+ JOIN \w+\.\w+ \((\w+)\) AS "\w+"$/)[1];
        const sourceAgg = config.aggregates.find((a) => a.typeName === sourceType);
        const targetAgg = config.aggregates.find((a) => a.typeName === parts[4]);
        if (!sourceAgg || !targetAgg) {
            throw new Error('Invalid join clause');
        }
        const sourceProp = sourceAgg.properties[parts[3]];
        let onClause = '';
        if (sourceProp.relation !== undefined) {
            if (sourceProp.relation === 'ONE-TO-ONE') {
                onClause = `"${parts[2]}"."${parts[3]}" = ${parts[5]}.id`;
            }
            else {
                onClause = `"${parts[2]}".id = ${parts[5]}."${sourceProp.foreignColumn}"`;
            }
            if (args.schema === 'Analytic') {
                onClause += ` AND ${parts[5]}."recordActiveFrom" <= COALESCE("${parts[2]}"."recordActiveTo",now()) AND COALESCE(${parts[5]}."recordActiveTo",now()) >= "${parts[2]}"."recordActiveFrom"`;
            }
        }
        else {
            throw new Error('Invalid join clause');
        }
        return `${parts[1]} ${schema}."${targetAgg.objectStore.table}" ${parts[5]} ON ${onClause}`;
    }).join(`
    `);
    return `${aggregateResults && (args.orderBy || args.maxResults) ? 'SELECT * FROM (' : ''}SELECT ${selects.join(',')} FROM ${schema}."${agg.objectStore.table}" record
    ${joins ? joins : ''}
    ${args.whereClause ? `WHERE ${args.whereClause}` : ''}
    ${aggregateResults && ((_e = args.groupBy) === null || _e === void 0 ? void 0 : _e.length) ? `GROUP BY ${args.groupBy.join(',')}` : ''}
    ${aggregateResults && args.havingClause ? `HAVING ${args.havingClause}` : ''}
    ${aggregateResults && (args.orderBy || args.maxResults) ? ') sq' : ''}
    ${((_f = args.orderBy) === null || _f === void 0 ? void 0 : _f.length) ? `ORDER BY ${args.orderBy.join(',')}` : ''}
    ${args.maxResults ? `LIMIT ${args.maxResults}` : ''}
    `;
};
exports.validateAdvancedSearch = validateAdvancedSearch;
