import NumberInputControl from './NumberInput';
import GroupControl from './GroupControl';
import TextInputControl from './TextInput';
import MapControl from './MapControl';
import StreetViewControl from './StreetViewControl';
import MediaControl from './MediaInput';
import ListControl from './ListControl';
import ChartControl from './ChartControl';
import {
  V2GroupComponentDef,
  V2TextTemplateComponent,
  V2NumberTemplateComponent,
  V2MapComponent,
  V2StreetViewComponent,
  V2MediaComponent,
  V2ListComponent,
  V2ChartComponent,
} from '@terragotech/page-renderer';
import { PageControlProps, NestedPageControlProps } from '../PageTypes';

interface PageBuilderControlMap {
  group?: React.ComponentType<PageControlProps<V2GroupComponentDef>>;
  text?: React.ComponentType<NestedPageControlProps<V2TextTemplateComponent>>;
  datetime?: React.ComponentType<NestedPageControlProps<V2TextTemplateComponent>>;
  date?: React.ComponentType<NestedPageControlProps<V2TextTemplateComponent>>;
  time?: React.ComponentType<NestedPageControlProps<V2TextTemplateComponent>>;
  image?: React.ComponentType<NestedPageControlProps<V2MediaComponent>>;
  file?: React.ComponentType<NestedPageControlProps<V2MediaComponent>>;
  number?: React.ComponentType<NestedPageControlProps<V2NumberTemplateComponent>>;
  aggregateMap?: React.ComponentType<NestedPageControlProps<V2MapComponent>>;
  streetView?: React.ComponentType<NestedPageControlProps<V2StreetViewComponent>>;
  list?: React.ComponentType<NestedPageControlProps<V2ListComponent>>;
  chart?: React.ComponentType<NestedPageControlProps<V2ChartComponent>>;
}

export const controlMap: PageBuilderControlMap = {
  group: GroupControl,
  text: TextInputControl,
  date: TextInputControl,
  time: TextInputControl,
  datetime: TextInputControl,
  image: MediaControl,
  file: MediaControl,
  number: NumberInputControl,
  aggregateMap: MapControl,
  streetView: StreetViewControl,
  list: ListControl,
  chart: ChartControl,
};
export { default as PageFrame } from './PageFrame';
