import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { ConfigContext } from 'context/ConfigContext';
import { useAggregateAPI } from 'context/fakeAPIHooks/useAggregateAPI';
import { COMMAND_LEFT_PANEL } from 'utils/Utils';
import { getAggregateIndex } from 'utils/navigationUtils';
import TGEditor from 'views/components/TGEditor';

import CommandUtilization from './CommandUtilization';

const CommentEditor = ({ isCommand }: { isCommand?: boolean }) => {
  const classes = useStyles();
  const AggregateAPI = useAggregateAPI();
  const { aggregate, commandName = '', eventName = '' } = useParams() as {
    aggregate: string;
    commandName: string;
    eventName: string;
  };
  const { config, getAggregate } = useContext(ConfigContext);
  const aggrIndex = getAggregateIndex(config, aggregate);
  const aggregateType = commandName ? 'commands' : 'events';
  const aggregateInfo = getAggregate(aggrIndex);
  const name = commandName || eventName;
  const internalDescription = _.get(
    aggregateInfo,
    `${aggregateType}.${name}.$internalDescription`,
    ''
  );

  const toggleEdit = async (isEditMode: boolean, comment: string) => {
    if (isEditMode) {
      const action = commandName ? AggregateAPI.addCommandComment : AggregateAPI.addEventComment;
      const { error } = await action(aggrIndex, name, comment);
      if (error) return;
    }
  };

  return (
    <Box className={classes.rightPanel}>
      {isCommand && (
        <CommandUtilization
          command={name}
          aggregateInfo={aggregateInfo}
        />
      )}
      <TGEditor
        {...{
          description: internalDescription,
          onToggle: toggleEdit,
          title: 'Internal Description',
          name,
          isAccordion: isCommand,
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightPanel: {
      display: 'flex',
      flexDirection: 'column',
      padding: 25,
      width: `calc(100% - ${COMMAND_LEFT_PANEL}px)`,
      height: '100%',
      overflowY: 'auto',
    },
  })
);

export default CommentEditor;
