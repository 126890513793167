import { useApolloClient, gql } from '@apollo/client';

// TODO [S5-3129]: Rename to Online (along with `useOfflineProximityLookup.ts`) when mobile/desktop offline strategy is implemented.
export const useAdvancedSearch = () => {
  const apolloClient = useApolloClient();
  const onlineLookup = (
    schema?: string,
    aggregateResults?: string,
    selectValues?: string[],
    groupBy?: string[],
    joins?: string[],
    recordType?: string,
    whereClause?: string,
    havingClause?: string,
    orderBy?: string[],
    maxResults?: number,
    inputs?: any) => {
    return apolloClient
      .query({
        query: gql`
	query aggregateSearch($selectValues: [String]!, $whereClause: String, $havingClause: String, $schema: String, $recordType: String, $orderBy: [String], $joins: [String], $maxResults: Int, $inputs: JSON, $groupBy: [String], $aggregateResults: String) { 
    aggregateSearch(
      selectValues: $selectValues
      whereClause: $whereClause
      havingClause: $havingClause
      joins: $joins
      schema: $schema
      recordType: $recordType
      orderBy: $orderBy
      maxResults: $maxResults
      inputs: $inputs
      groupBy: $groupBy
      aggregateResults: $aggregateResults)
    }
	`,
        fetchPolicy: 'no-cache',
        variables: {
          schema,
          aggregateResults,
          selectValues,
          groupBy,
          recordType,
          whereClause,
          havingClause,
          joins,
          orderBy,
          maxResults,
          inputs
        }
      })
      .then(res => {
        return res.data.aggregateSearch
      });
  };
  return onlineLookup;
};
