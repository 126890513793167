import { FunctionComponent, useMemo } from 'react';
import { V2ChartComponent } from '@terragotech/page-renderer';
import styled from 'styled-components';
import { NestedPageControlProps } from '../PageTypes';
import { StandardInputFrame, Label, LabelSubText } from './Common';
import _ from 'lodash';
import { CustomPageBadges } from './CustomPageBadges';
import { chartTypeOptions } from '../../../../../../components/PageDialog/ChartEditForm';

const Placeholder = styled.div`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #aaaaaa;
  margin-right: 15px;
`;
const PlaceHolderFrame = styled.div`
  display: flex;
  margin-left: 15px;
  margin-bottom: 12px;
`;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;
const ChartControl: FunctionComponent<NestedPageControlProps<V2ChartComponent>> = (props) => {
  const { controlDefinition, pasted, isPageEditor } = props;

  const controlTypeLabel = useMemo(() => _.upperFirst(controlDefinition.type), [controlDefinition.type]);
  const chartTypeLabel = useMemo(() => chartTypeOptions[controlDefinition.chartType] ?? 'select type', [controlDefinition.chartType]);

  return (
    <StandardInputFrame>
      <Label
        text={controlDefinition.label}
        conditional={Boolean(controlDefinition.displayOptions)}
        isPageEditor={isPageEditor}
      />
      {isPageEditor && (
        <LabelSubText isDisplayOptionsEnabled={Boolean(controlDefinition.displayOptions)}>
          {`${controlTypeLabel} (${chartTypeLabel})`}
        </LabelSubText>
      )}
      <PlaceHolderFrame>
        <Placeholder>{controlDefinition.placeholder}</Placeholder>
      </PlaceHolderFrame>
      <CustomPageBadges controlDefinition={controlDefinition} />
      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default ChartControl;
