import React from 'react';
import styled from 'styled-components';
import { isEqual, cloneDeep } from 'lodash';
import {
  V2GroupComponentDef,
  V2PageComponentDef,
  V2PageTemplate,
} from '@terragotech/page-renderer';
import { blue } from '@material-ui/core/colors';
import { usePageDialog } from '../../../../../components/PageDialog/PageDialogService';
import { successMsg } from '../../../../../components/SnackbarUtilsConfigurator';
import { useMapperRefChanger } from '../../../../../utils/useMapperRefChanger';
import { getAggregateIndex } from '../../../../../utils/navigationUtils';
import { useConfig } from '../../../../../context/ConfigContext';
import { GroupEditForm } from '../../../../../components/PageDialog/GroupEditForm';
import {
  TextInputEditForm,
  TextTemplateWithName,
} from '../../../../../components/PageDialog/TextInputEditForm';
import { PageContextProvider } from '../../../../../components/PageDialog/contexts/PageContext';
import { AggregateContextProvider } from '../../../../../components/FormDialog/contexts/AggregateContext';
import { controlMap } from './MobileTheme';
import { Draggable } from 'react-beautiful-dnd';
import { MapEditForm } from '../../../../../components/PageDialog/MapEditForm';
import { StreetViewEditForm } from '../../../../../components/PageDialog/StreetViewEditForm';
import { colors } from 'utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core';
import { faPenToSquare, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { MediaEditForm } from '../../../../../components/PageDialog/MediaEditForm';
import SymbolValueContextProvider from 'views/pages/settings/SymbolContext';
import { AGGREGATE_MAP_MODAL_WIDTH, CONFIRMATION } from '../../../../../utils/Utils';
import { useConfirmDialog } from 'context/ConfirmContext';
import ListEditForm from '../../../../../components/PageDialog/ListEditForm';
import { ChartEditForm } from '../../../../../components/PageDialog/ChartEditForm';

interface PageLayoutListProps {
  pageDefinition: V2PageTemplate;
  fullPageDefinition: V2PageTemplate;
  setPageDefinition: (val: V2PageTemplate) => void;
  selectedItems: V2PageTemplate;
  setSelectedItems: (val: V2PageTemplate) => void;
  lastPastedPageTemplate: V2PageTemplate | null;
  setRefresh: (boolean: boolean) => void;
  groupDragging: boolean;
  focusedItem: string;
  setFocusedItem: (val: string) => void;
  row: number;
  column: number;
}

export type V2PageComponentDefWithName = V2PageComponentDef & { name: string; droppableId: string };

export const PageLayoutList: React.FC<PageLayoutListProps> = ({
  pageDefinition,
  setPageDefinition,
  selectedItems,
  setSelectedItems,
  lastPastedPageTemplate,
  setRefresh,
  groupDragging,
  fullPageDefinition,
  focusedItem,
  setFocusedItem,
  row,
  column,
}) => {
  const classes = useStyles();
  const { config } = useConfig();
  const aggrName = fullPageDefinition.aggregateType;
  const aggrIndex = getAggregateIndex(config, aggrName);
  const currentAggregate = config?.aggregates?.[aggrIndex];
  const pageDialog = usePageDialog();
  const mapperRefChanger = useMapperRefChanger();
  const { openConfirmation } = useConfirmDialog();

  const handleSelectedItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    pageComponentName: string,
    group?: string
  ) => {
    const fromDefinitionCopy = cloneDeep(pageDefinition);

    const addItemToPage = () => {
      const copySelectedItems = cloneDeep(selectedItems);
      copySelectedItems.elements[pageComponentName] = {
        row: 1,
        column: 1,
        columnSpan: 1,
        component: pageComponentValue,
      };
      return copySelectedItems;
    };

    const removeItemFromPage = (declaredItemToRemove?: string) => {
      const pageItemNameToRemove = declaredItemToRemove || pageComponentName;
      const copySelectedItems = cloneDeep(selectedItems);
      delete copySelectedItems.elements[pageItemNameToRemove];
      return copySelectedItems;
    };

    let pageComponentValue: V2PageComponentDef;

    pageComponentValue = fromDefinitionCopy.elements[pageComponentName]
      .component as V2PageComponentDef;
    event.stopPropagation();

    // In that case, event.ctrlKey does the trick.
    if (event.ctrlKey || event.metaKey) {
      if (!selectedItems.elements.hasOwnProperty(pageComponentName)) {
        setSelectedItems(addItemToPage());
      } else {
        setSelectedItems(removeItemFromPage());
      }
    } else if (!isEqual(emptySelectedItems, selectedItems)) {
      setSelectedItems(emptySelectedItems);
    }
    setFocusedItem(`${group || ''}${pageComponentName}`);
  };

  const editItem = async (
    component: V2PageComponentDef,
    name: string,
    droppableId: string,
    index: number
  ) => {
    const removeItemFromPage = () => {
      delete page.elements[name];
    };
    const addModifiedItemToPage = () => {
      const deleteTextTemp = value as Partial<{ name: string }>;
      delete deleteTextTemp.name;
      page.elements[newName] = { row, column, columnSpan, component: value };
    };

    let page = cloneDeep(pageDefinition);
    const { row, column, columnSpan } = page.elements[name];
    const item: V2PageComponentDefWithName = { ...cloneDeep(component), name, droppableId };
    const value: TextTemplateWithName = await pageDialog<typeof TextInputEditForm>(
      // I made a questionable choice to move these values into context, without realizing the dialog is in a separate branch of the tree
      (props) => (
        <AggregateContextProvider aggregateConfig={currentAggregate}>
          <PageContextProvider pageDefinition={fullPageDefinition}>
            <div style={styles.pageControlContainer}>{getPageComponent(item, props)}</div>
          </PageContextProvider>
        </AggregateContextProvider>
      ),
      true,
      component?.type === 'aggregateMap' ? AGGREGATE_MAP_MODAL_WIDTH : undefined
    );
    const { name: newName } = value;
    successMsg(`Page element "${name}" has been successfully edited`);
    if (droppableId === 'page') {
      removeItemFromPage();
      addModifiedItemToPage();
      page = mapperRefChanger.renameFormReferences(page, name, newName);
      setPageDefinition(page);
      try {
        setRefresh(false);
      } catch (error) {
        console.error(error);
      }
      return;
    }
  };
  const deleteItem = async (id: string, droppableId: string, index: number) => {
    let page = cloneDeep(pageDefinition);
    const removeItemFromPage = () => {
      delete page.elements[id];
    };
    successMsg(`Page element "${id}" has been successfully deleted`);
    if (droppableId === 'page') {
      removeItemFromPage();
      page = mapperRefChanger.removeFormReferences(page, id);
      setPageDefinition(page);
      return;
    }
  };
  const shrinkDetails = (name: string) => {
    const component = pageDefinition.elements[name];
    if (component && component.columnSpan > 1) {
      const shrinkDetail = {
        row: component.row,
        columns: component.columnSpan,
      };
      return shrinkDetail;
    }
  };

  const onHandleDeleteItem = async (id: string, index: number) => {
    const props = CONFIRMATION.pageLayout({ name: id });
    const status = await openConfirmation(props);
    if (status === 'confirm') {
      deleteItem(id, 'page', index);
    }
  };

  return (
    <>
      {Object.entries(pageDefinition.elements)
        .filter(([_, e]) => e.row === row && e.column === column)
        .map(([name, _], index) => {
          const component = pageDefinition.elements[name].component as V2GroupComponentDef;
          const Control = component && controlMap[component.type];
          if (Control) {
            return (
              <Draggable key={name} draggableId={name} index={index}>
                {(provided) => (
                  <PanelContainer>
                    <PanelItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={focusedItem === name ? classes.focus : ''}
                      isSelected={isEqual(
                        selectedItems.elements[name],
                        pageDefinition.elements[name]
                      )}
                      hasMargin={component.type === 'group'}
                      onClick={(e) => handleSelectedItem(e, name)}
                    >
                      <Control
                        controlDefinition={component}
                        name={name}
                        shrinkDetail={shrinkDetails(name)}
                        isPageEditor={true}
                        isDropDisabled={groupDragging}
                        focusedItem={focusedItem}
                        selectedItems={selectedItems}
                        setSelectedItems={handleSelectedItem}
                        editItem={editItem}
                        deleteItem={deleteItem}
                        pasted={Object.keys(lastPastedPageTemplate?.elements || {}).includes(name)}
                        lastPastedPageGroup={
                          lastPastedPageTemplate?.elements[name].component as V2GroupComponentDef
                        }
                      />
                      {focusedItem === name && (
                        <ItemButtonsContainer isGroup={component.type === 'group'}>
                          <div
                            onClick={() => {
                              editItem(component, name, 'page', index);
                            }}
                            className={classes.iconButtonRoot}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} style={styles.faIcon} />
                          </div>
                          <div
                            onClick={() => onHandleDeleteItem(name, index)}
                            className={classes.iconButtonRoot}
                          >
                            <FontAwesomeIcon icon={faTrashCan} style={styles.faIcon} />
                          </div>
                        </ItemButtonsContainer>
                      )}
                    </PanelItem>
                  </PanelContainer>
                )}
              </Draggable>
            );
          } else {
            console.log(`Couldn't find component: ${pageDefinition.elements[name]}`);
          }
          return null;
        })}
    </>
  );
};

const getPageComponent = (
  item: V2PageComponentDefWithName,
  props: React.PropsWithChildren<{
    onClose: () => void;
    onSubmit: <T>(result: T) => void;
  }>
) => {
  switch (item.type) {
    case 'group':
      return <GroupEditForm component={item} {...props} />;
    case 'aggregateMap':
      return (
        <SymbolValueContextProvider>
          <MapEditForm component={item} {...props} />
        </SymbolValueContextProvider>
      );
    case 'streetView':
      return <StreetViewEditForm component={item} {...props} />;
    case 'text':
    case 'number':
    case 'date':
    case 'time':
    case 'datetime':
      return <TextInputEditForm component={item} {...props} />;
    case 'image':
    case 'file':
      return <MediaEditForm component={item} {...props} />;
    case 'chart':
      return <ChartEditForm component={item} {...props} />;
    case 'list':
      return <ListEditForm component={item} {...props} />;
    default:
      return null;
  }
};

const emptySelectedItems = {
  rows: 1,
  columns: 1,
  allowDynamicSizing: true,
  elements: {},
};
const styles = {
  pageControlContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: 'auto',
    height: '100%',
  } as React.CSSProperties,
  editIcon: { color: colors.black54 },
  deleteIcon: { color: colors.black54, marginRight: 3 },
  faIcon: {
    fontSize: 18,
    color: colors.semiTransparentBlack2,
    padding: 8,
  },
};
const PanelItem = styled.div<{ hasMargin?: boolean; isSelected?: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  padding-left: 8px;
  background: ${colors.white};
  color: ${colors.title};
  border-bottom: solid 1px ${colors.grayLine};
  box-shadow: 0px 1px 4px 0px ${colors.black15};
  margin-bottom: ${(props) => props.hasMargin && '0px'};
  margin-top: ${(props) => props.hasMargin && '0px'};
  border: ${(props) => props.isSelected && `1px solid ${blue[300]}`};
  border-radius: 5px;
  &:focus {
    outline: none;
  }
`;
const PanelContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 6px;
  background-color: ${colors.lotion};
  border-radius: 5px;
  line-height: 100%;
`;
const ItemButtonsContainer = styled.div<{ isGroup?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${(props) => (props.isGroup ? 0 : 3)};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5;
`;

const useStyles = makeStyles((theme) => ({
  focus: {
    border: `1px solid ${theme.palette.primary.main} !important`,
  },
  iconButtonRoot: {
    '&:hover': {
      background: colors.transparentBlack,
      borderRadius: '50%',
      cursor: 'pointer',
    },
  },
}));
