import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Tab, Tabs, Theme, Typography, makeStyles, Divider } from '@material-ui/core';
import { useBlocker, useParams } from 'react-router-dom';
import TabPanel from '../TabPanel';
import { cloneDeep, isEqual } from 'lodash';
import FormEditor, { FormEditorEditedComponentProps } from './tabs/FormEditor';
import TGBreadCrumbs from '../common/TGBreadCrumbs';
import { ConfigContext } from '../../../../../context/ConfigContext';
import { getAggregateIndex } from '../../../../../utils/navigationUtils';
import {
  errorMsg,
  successMsg,
  warningMsg,
} from '../../../../../components/SnackbarUtilsConfigurator';
import { useAggregateAPI } from '../../../../../context/fakeAPIHooks/useAggregateAPI';
import { useCommandActionRefChanger } from '../../../../../utils/useCommandActionRefChanger';
import {
  V2FormComponentDef,
  V2FormTemplate,
  V2GroupComponentDef,
} from '@terragotech/form-renderer';
import { CONFIRMATION, getLocalSchema, ROOT_RECORDS_ROUTE } from '../../../../../utils/Utils';
import { propertiesToSchema } from '../../../../../pages/aggregates/utils/PropertiesToSchemaConverter';
import { convertV2FormTemplateToJsonSchema } from '../../../../../pages/aggregates/utils/V2FormTemplateToJsonSchema';
import {
  CommandVersionDefinition,
  FormCommandDefinition,
  OneToManyCommandToEventMapping,
} from '@terragotech/gen5-config-lib';
import { mapScenarios, NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { MapperList } from '../../../../../components/FormElements';
import ConfigurationHeader from '../common/ConfigurationHeader';
import CommandTypeConfiguration, {
  CommandType,
  CommandTypeOption,
  CreateCommand,
} from '../common/CommandTypeConfiguration';
import { cleanFormTemplate } from '../../../../../pages/aggregates/utils/formUtils';
import { MaterialTableRef } from '../../../../../components/EditableTable';
import { colors } from 'utils/colors';
import { V2FormComponentDefWithName } from '../../../../../pages/aggregates/components/CommandFormEditor/CommandFormList';
import { useMapperRefChanger } from '../../../../../utils/useMapperRefChanger';
import useCommonStyles from 'views/useCommonStyles';
import WorkflowPageContainer from '../../../../../pages/workflow/WorkflowPageContainer';
import WorkflowSimulatorModal from '../../../../../components/PageDialog/WorkflowSimulatorModal';
import useRouteBlocker from 'common/useBlocker';
import { EditedDefData } from 'utils/types';
import { useConfirmDialog } from 'context/ConfirmContext';
import { useRedirect } from 'context/RedirectModalContext';
import CommandToEventMapper, { COMMAND_MULTI_EVENT_MAPPING_SCENARIO } from './tabs/CommandToEventMapper';
import InitialDataMapper, { INITIAL_DATA_MAPPING_SCENARIO } from './tabs/InitialDataMapper';
import { UseEventMapDiagramOutput } from 'map-editor/src/Hooks/useDataMapDiagram';

const AGGREGATE_KEY = 'aggregate';
export const NON_AGGREGATE_KEY = 'nonaggregate';
const DEFAULT_LIMIT_MAX_OCCURRENCE = 50;
enum COMMAND_TYPE_SUB_MENUS {
  createCommand_LABEL = 'Create Command',
  PUBLIC_LABEL = 'Public',
  LIMIT_MAX_OCCURANCE_LABEL = 'Limit max occurrence',
  USER_CONFIRMATION_TEXT_LABEL = 'User Confirmation Text',
}
const initialCommandTabs = [
  'Form Editor',
  'Form Validation',
  'Initial Data Map',
  'Command to Event Mapping',
].map((label) => ({ label }));

const Configuration = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const AggregateAPI = useAggregateAPI();
  const { config, getCommandVersions, getCommandVersion } = useContext(ConfigContext);
  const mapperRefChanger = useMapperRefChanger();
  const { aggregate, commandName, commandVersion } = useParams() as {
    aggregate: string;
    commandName: string;
    commandVersion: string;
  };
  const [
    formEditorEditedData,
    setFormEditorEditedData,
  ] = useState<null | V2FormComponentDefWithName>(null);
  const [formcomponentNameError, setFormComponentNameError] = useState<boolean>(false);
  const [
    formEditorEditedComponent,
    setFormEditorEditedComponent,
  ] = useState<FormEditorEditedComponentProps | null>(null);
  const aggrIndex = getAggregateIndex(config, aggregate);
  const aggregateTypeName = config.aggregates[aggrIndex]?.objectStore?.table;
  const aggregateLabelProperty = config.aggregates[aggrIndex]?.labelProperty || 'title';
  const commandTitle = config.aggregates[aggrIndex]?.commands?.[commandName]?.defaultLabel;
  const tableRef = useRef<MaterialTableRef>(null);
  const commandsRef = useRef(config.aggregates[aggrIndex]?.commands);
  const commandToEventMapperRef = useRef<null | UseEventMapDiagramOutput>(null);
  const initialMapperRef = useRef<null | UseEventMapDiagramOutput>(null);

  useEffect(() => {
    if (!isEqual(commandsRef.current, config.aggregates[aggrIndex]?.commands)) {
      commandsRef.current = config.aggregates[aggrIndex]?.commands;
    }
  }, [config.aggregates, aggrIndex, commandName, commandVersion]);

  const version = useMemo(
    () => commandsRef.current?.[commandName]?.versions[+commandVersion],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [commandsRef.current, commandName, commandVersion]
  );

  const [reset, setReset] = useState<number>(0);
  const [focusedItem, setFocusedItem] = useState('');
  const [showSimulator, setShowSimulator] = useState(false);
  const [showWorkflow, setShowWorkflow] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [simulatorAggregateId, setSimulatorAggregateId] = useState<string | undefined>();
  const [commandType, setCommandType] = useState<CommandType>(
    version?.isImportCommand ? 'IMPORT' : (version?.commandType as CommandType)
  );
  const [createCommandType, setCreateCommandType] = useState<CreateCommand>(
    version?.hasNoAggregateIdCommand ? NON_AGGREGATE_KEY : AGGREGATE_KEY
  );
  // Command options
  const [isCreationCommand, setIsCreationCommand] = useState<boolean>(
    version?.fabOptionEnabled || version?.isCreationCommand || false
  );
  const [isPublic, setIsPublic] = useState<boolean>(version?.isPublic || false);
  const [isMaxLimit, setIsMaxLimit] = useState<boolean>(
    !!version?.limitMaxOccurrence?.toString().length
  );
  const [confirmationText, setConfirmationText] = useState<string>(version?.confirmationText || '');
  const [isConfirmationText, setIsConfirmationText] = useState<boolean>(!!confirmationText.length);
  const [isImportIntegration, setIsImportIntegration] = useState<boolean>(
    version?.isIntegrationImport || false
  );
  const [isImportCommand, setIsImportCommand] = useState<boolean>(
    version?.isImportCommand || false
  );
  const [emptyIsNull, setEmptyIsNull] = useState<boolean>(version?.emptyIsNull || false);
  const commandActionRefChanger = useCommandActionRefChanger();
  const [limitMaxOccurrence, setLimitMaxOccurrence] = useState<number>(
    version?.limitMaxOccurrence || DEFAULT_LIMIT_MAX_OCCURRENCE
  );
  const [fileType, setFileType] = useState<string>(version?.fileTypes || 'xlsx,csv');
  const [commandTabs, setCommandTabs] = useState<Array<{ label: string }>>([]);
  const initialFormDefinition = useCallback(
    () =>
      (version &&
        (version as FormCommandDefinition).formDefinition &&
        cloneDeep((version as FormCommandDefinition).formDefinition)) || {
        components: {},
        order: [],
      },
    [version]
  );
  const [formDefinition, setFormDefinition] = useState<V2FormTemplate>(initialFormDefinition());
  const [versionNo, setVersionNo] = useState<number>(version?.version || 0);
  const [savedDefinition, setSavedDefinition] = useState<V2FormTemplate>(initialFormDefinition());
  const [initialDef, setInitialDef] = useState<V2FormTemplate>(initialFormDefinition());
  const stateSchema = propertiesToSchema(config?.aggregates?.[aggrIndex]?.properties);
  const formSchema = convertV2FormTemplateToJsonSchema(formDefinition);
  const [mapping, setMapping] = useState<NodeMapDefinition | undefined>(
    (version?.cmdToEventsMapping as OneToManyCommandToEventMapping)?.mapping
  );
  const [isSynchronous, setIsSynchronous] = useState(version?.isSynchronous);
  const [isDirty, setIsDirty] = useState(false);
  const [currentEditItem, setCurrentEditItem] = useState<null | V2FormComponentDefWithName>(null);
  useEffect(() => {
    const initialFormDef = initialFormDefinition();
    setSavedDefinition(initialFormDef);
    if (version && version.version !== versionNo) {
      setInitialDef(initialFormDef);
      setVersionNo(version.version);
    }
  }, [version, initialFormDefinition, versionNo]);

  const selectFirstComponent = useCallback((formDef: V2FormTemplate) => {
    if (formDef.order.length > 0) {
      const name = formDef.order[0];
      const component = formDef.components[name];
      setCurrentEditItem({ ...component, name, index: 0, droppableId: 'form' });
      setFocusedItem(name);
    } else {
      setCurrentEditItem(null);
      setFocusedItem('');
    }
  }, []);

  useEffect(() => {
    // on initial and command version changed
    selectFirstComponent(initialDef);
  }, [initialDef, selectFirstComponent]);
  const commandTypeOptions: CommandTypeOption[] = [
    {
      selected: isCreationCommand,
      type: 'select',
      label: COMMAND_TYPE_SUB_MENUS.createCommand_LABEL,
      value: [
        { key: AGGREGATE_KEY, value: 'Aggregate' },
        { key: NON_AGGREGATE_KEY, value: 'Non-Aggregate' },
      ],
      style: classes.commandType,
    },
    { selected: isPublic, label: COMMAND_TYPE_SUB_MENUS.PUBLIC_LABEL },
    {
      selected: isMaxLimit,
      type: 'number',
      label: COMMAND_TYPE_SUB_MENUS.LIMIT_MAX_OCCURANCE_LABEL,
      value: limitMaxOccurrence?.toString() || DEFAULT_LIMIT_MAX_OCCURRENCE.toString(),
      style: classes.limitBox,
    },
    {
      selected: isConfirmationText,
      type: 'text',
      label: COMMAND_TYPE_SUB_MENUS.USER_CONFIRMATION_TEXT_LABEL,
      value: confirmationText,
      style: [classes.limitBox, classes.confirmBox].join(' '),
    },
  ];

  const mapperItems = [
    {
      key: 'error',
      title: 'Error Mapping',
      data: formDefinition.errorMaps || [],
      mapScenario: 'FORM_LEVEL_ERROR' as const,
      action: (data: NodeMapDefinition[]) => handleSetErrorMaps(data),
    },
    {
      key: 'warning',
      title: 'Warning Mapping',
      mapScenario: 'FORM_LEVEL_WARNING' as const,
      data: formDefinition.warningMaps || [],
      action: (data: NodeMapDefinition[]) => handleSetWarningMaps(data),
    },
  ];

  const { openConfirmation } = useConfirmDialog();

  const resetFormDefinition = () => {
    setFormDefinition({
      components: {},
      order: [],
    });
  };

  const updateCommandTab = useCallback(() => {
    let tabs = initialCommandTabs;
    const eventMapping = [{ label: 'Command to Event Mapping' }];
    switch (commandType) {
      case 'BUTTON': {
        tabs = eventMapping;
        setIsImportIntegration(false);
        setIsImportCommand(false);
        break;
      }
      case 'FORM': {
        setIsImportIntegration(false);
        setIsImportCommand(false);
        break;
      }
      case 'IMPORT': {
        setIsImportCommand(true);
        if (isImportIntegration) {
          tabs = eventMapping;
        }
        break;
      }
      case 'TIMER': {
        tabs = eventMapping;
        setIsImportIntegration(false);
        setIsImportCommand(false);
        break;
      }
      default:
        tabs = [];
    }
    setCommandTabs(tabs);
    setActiveTab(0);
  }, [commandType, isImportIntegration]);

  useEffect(() => {
    updateCommandTab();
  }, [commandType, isImportIntegration, updateCommandTab]);

  const resetCommandTypeConfig = useCallback(() => {
    if (version) {
      const {
        isSynchronous,
        emptyIsNull,
        isIntegrationImport,
        commandType,
        isImportCommand,
        confirmationText,
        isPublic,
        limitMaxOccurrence,
        fabOptionEnabled,
        isCreationCommand,
        hasNoAggregateIdCommand,
      } = version;
      const oldConfirmation = confirmationText || '';
      setIsSynchronous(isSynchronous);
      setEmptyIsNull(emptyIsNull || false);
      setIsImportIntegration(isIntegrationImport || false);
      setIsImportCommand(isImportCommand || commandType === 'IMPORT' ? true : false);
      setCommandType(isImportCommand ? 'IMPORT' : (commandType as CommandType));
      setConfirmationText(oldConfirmation);
      setIsConfirmationText(!!oldConfirmation.length);
      setIsPublic(isPublic || false);
      setCreateCommandType(hasNoAggregateIdCommand ? NON_AGGREGATE_KEY : AGGREGATE_KEY);
      setIsCreationCommand(fabOptionEnabled || isCreationCommand || false);
      setIsMaxLimit(!!limitMaxOccurrence?.toString().length);
      setLimitMaxOccurrence(limitMaxOccurrence || DEFAULT_LIMIT_MAX_OCCURRENCE);
    }
    setIsDirty(false);
  }, [version]);

  useEffect(() => {
    resetCommandTypeConfig();
    setMapping((version?.cmdToEventsMapping as OneToManyCommandToEventMapping)?.mapping);
    if (version && (version.commandType === 'FORM' || version.commandType === 'IMPORT') && (version as FormCommandDefinition).formDefinition) {
      setFormDefinition(cloneDeep((version as FormCommandDefinition).formDefinition));
    } else {
      setFormDefinition({
        components: {},
        order: [],
      });
    }
  }, [version, reset, resetCommandTypeConfig]);

  const onChangeCommndTypeData = (
    action:
      | Dispatch<SetStateAction<CommandType>>
      | Dispatch<SetStateAction<boolean>>
      | Dispatch<SetStateAction<string>>
      | Dispatch<SetStateAction<CreateCommand>>
  ) => (value: CommandType | boolean | string | CreateCommand) => {
    setIsDirty(true);
    // @ts-ignore
    action(value);
  };

  const onChangeOption = (checked: boolean, index: number) => {
    setIsDirty(true);
    switch (index) {
      case 0:
        setIsCreationCommand(checked);
        break;
      case 1:
        setIsPublic(checked);
        break;
      case 2:
        setIsMaxLimit(checked);
        break;
      case 3:
        setIsConfirmationText(checked);
        break;
      default:
    }
  };

  const onChangeText = (value: string, key: string) => {
    setIsDirty(true);
    switch (key) {
      case COMMAND_TYPE_SUB_MENUS.LIMIT_MAX_OCCURANCE_LABEL:
        setLimitMaxOccurrence(Number(value) || DEFAULT_LIMIT_MAX_OCCURRENCE);
        break;
      case COMMAND_TYPE_SUB_MENUS.USER_CONFIRMATION_TEXT_LABEL:
        setConfirmationText(value);
        break;
      default:
    }
  };

  const handleCancelChanges = async () => {
    try {
      const status = await openConfirmation(CONFIRMATION.commonCancel);
      if (status === 'confirm') {
        setReset((prev) => prev + 1);
      }
    } catch (error) {
      console.log('request cancelled');
    }
  };

  const shouldRefsBeRemapped = async (commandName: string, commandVersion: number) => {
    try {
      const props = CONFIRMATION.remapping({ commandName, commandVersion });
      const status = await openConfirmation(props);
      return status === 'confirm';
    } catch {
      return false;
    }
  };

  const updateSelectedComponent = () => {
    if (currentEditItem && focusedItem) {
      if (formDefinition.order.length === 0) {
        selectFirstComponent(formDefinition);
      } else {
        const droppableId = currentEditItem.droppableId;
        let component;
        let index;
        if (droppableId === 'form') {
          component = formDefinition.components[focusedItem];
          index = formDefinition.order.findIndex((order) => order === focusedItem);
        } else {
          const childName = focusedItem.split(droppableId)[1];
          component = (formDefinition.components[droppableId] as V2GroupComponentDef).template
            .components[childName];
          index = (formDefinition.components[
            droppableId
          ] as V2GroupComponentDef).template.order.findIndex((order) => order === childName);
        }
        if (component) {
          setCurrentEditItem({ ...component, name: focusedItem, index, droppableId });
        } else {
          selectFirstComponent(formDefinition);
        }
      }
    } else {
      selectFirstComponent(formDefinition);
    }
  };

  const handleAddVersion = async (commandName: string, versionIndex: number) => {
    let doRemap = false;
    const commandVersion = getCommandVersion(
      getAggregateIndex(config, aggregate),
      commandName,
      versionIndex
    )?.version;
    if (!commandVersion) return;
    if (commandActionRefChanger.isAnyReference(config, aggregate, commandName, commandVersion)) {
      doRemap = await shouldRefsBeRemapped(commandName, commandVersion);
    }
    const { error } = await AggregateAPI.addCommandVersion(
      aggrIndex,
      commandName,
      versionIndex,
      commandVersion,
      doRemap
    );
    if (error) return;
    successMsg(`New version of the "${commandName}" has been successfully created`);
  };

  const handleRemoveVersion = async (
    commandName: string,
    versionIndex: number,
    commandVersion: number
  ) => {
    const versions = getCommandVersions(aggrIndex, commandName);
    if (versions?.length === 1)
      return errorMsg(
        'At least one version of the command must remain. Consider deleting the entire command instead'
      );

    const { error } = await AggregateAPI.removeCommandVersion(
      aggrIndex,
      commandName,
      versionIndex,
      commandVersion
    );
    if (error) return;
    successMsg(
      `The version "${commandVersion}" of the command "${commandName}" has been successfully deleted.`
    );
  };

  const handleSetInitialDataMap = (data: NodeMapDefinition | undefined) => {
    const formDefinitionClone = cloneDeep(formDefinition);
    formDefinitionClone.initialDataMap = JSON.parse(JSON.stringify(data));
    setFormDefinition(formDefinitionClone);
    return formDefinitionClone;
  };

  const handleCommandsToEventMap = (data: NodeMapDefinition | undefined) => {
    setMapping(data);
    return data;
  };

  const handleSetErrorMaps = (data: NodeMapDefinition[]) => {
    const formDefinitionClone = cloneDeep(formDefinition);
    if (formDefinitionClone.hasOwnProperty('errorMaps') && !data) {
      delete formDefinitionClone.errorMaps;
    } else {
      formDefinitionClone.errorMaps = data;
    }
    setFormDefinition(formDefinitionClone);
  };

  const handleSetWarningMaps = (data: NodeMapDefinition[]) => {
    const formDefinitionClone = cloneDeep(formDefinition);
    if (formDefinitionClone.hasOwnProperty('warningMaps') && !data) {
      delete formDefinitionClone.warningMaps;
    } else {
      formDefinitionClone.warningMaps = data;
    }
    setFormDefinition(formDefinitionClone);
  };

  const checkConfirmationText = (): boolean => {
    let validConfrmtnTxt = true;
    if (isConfirmationText) {
      if (!confirmationText.trim().length) validConfrmtnTxt = false;
    } else {
      setConfirmationText('');
      delete version?.confirmationText;
    }
    return validConfrmtnTxt;
  };

  const checkLimitMaxOccurrence = (): boolean => {
    let validLimit = true;
    if (isMaxLimit) {
      if (!limitMaxOccurrence || limitMaxOccurrence <= 0) validLimit = false;
    } else {
      setLimitMaxOccurrence(DEFAULT_LIMIT_MAX_OCCURRENCE);
      delete version?.limitMaxOccurrence;
    }
    return validLimit;
  };
  const saveCommand = async (askConfirm = false) => {
    let status = askConfirm ? 'cancel' : 'confirm';
    if (askConfirm) {
      const props = CONFIRMATION.saveCommand;
      status = await openConfirmation(props);
    }
    if (status === 'confirm') {
      const newCommand = getNewCommand() as CommandVersionDefinition;
      const { error } = await AggregateAPI.updateCommandVersion(
        aggrIndex,
        commandName,
        +commandVersion,
        newCommand
      );
      if (error) return;
      const currentVersion =
        config.aggregates[aggrIndex]?.commands?.[commandName]?.versions[+commandVersion];
      const currentDefinition = (currentVersion as FormCommandDefinition).formDefinition;
      setSavedDefinition(currentDefinition);
      successMsg('The command has been saved');
      setIsDirty(false);
    }
  };

  const save = async () => {
    if (!isTabDirty() || (activeTab === 3 && !commandToEventMapperRef.current?.model) || (activeTab === 2 && !initialMapperRef.current?.model)) {
      // if initialMap or commmandToEvent mapping model not ready or no dirty bit
      return;
    }
    if (formcomponentNameError)
      return errorMsg(
        'An element with the same name already exists in the form. Rename it before save'
      );

    if (!checkLimitMaxOccurrence())
      return errorMsg('The limit to max occurrence must be defined and above 0');
    if (!checkConfirmationText()) return errorMsg('Confirmation Text is required');
    const { lastEditingRow, showAddRow } = tableRef?.current?.state || {};
    if (lastEditingRow || showAddRow)
      return warningMsg(
        'Editing of the table is not completed, confirm or reject the edited row before proceed with saving the form'
      );
    const oldCommand =
      config?.aggregates?.[aggrIndex].commands?.[commandName].versions?.[+commandVersion];
    let oldCommandType = oldCommand?.commandType;
    const oldCommandIsImport = oldCommand?.isImportCommand;
    if (oldCommandIsImport) oldCommandType = 'IMPORT';
    saveCommand(commandType !== oldCommandType);
  };

  const getNewCommand = () => ({
    ...version,
    commandType,
    isCreationCommand,
    isImportCommand: false,
    ...(isSynchronous !== undefined && isSynchronous !== null && { isSynchronous }),
    ...(isPublic !== undefined && isPublic !== null && { isPublic }),
    ...(commandType === 'IMPORT' && {
      isImportCommand: true,
      commandType: 'FORM',
      isIntegrationImport: isImportIntegration,
      fileTypes: isImportIntegration && fileType,
      emptyIsNull: emptyIsNull,
    }),
    ...(commandType !== 'TIMER' && {
      hasNoAggregateIdCommand: isCreationCommand && createCommandType === NON_AGGREGATE_KEY,
    }),
    ...(commandType !== 'TIMER' && { fabOptionEnabled: isCreationCommand }),
    ...(commandType !== 'TIMER' && {
      formDefinition: cleanFormTemplate(
        handleSetInitialDataMap(
          initialMapperRef.current?.model?.getMapDefinition() ||
            formDefinition.initialDataMap ||
            mapScenarios[INITIAL_DATA_MAPPING_SCENARIO].defaultNodeMap
        )
      ),
    }),
    ...(
      isConfirmationText &&
      confirmationText.length && { confirmationText }),
    ...(isMaxLimit && limitMaxOccurrence && { limitMaxOccurrence }),
    cmdToEventsMapping: {
      mappingType: 'ONE-TO-MANY',
      mapping: handleCommandsToEventMap(
        commandToEventMapperRef.current?.model?.getMapDefinition() ||
          mapping ||
          mapScenarios[COMMAND_MULTI_EVENT_MAPPING_SCENARIO].defaultNodeMap
      ),
    },
  });

  const removeItemFromForm = (index: number, name: string, copySelectedItems: V2FormTemplate) => {
    copySelectedItems.order.splice(index, 1);
    delete copySelectedItems.components[name];
  };

  const addModifiedItemToForm = useCallback(
    (index: number, newName: string, copySelectedItems: V2FormTemplate, editedData: unknown) => {
      const { name, ...rest } = editedData as V2FormComponentDefWithName;
      (copySelectedItems.components[newName] as V2FormComponentDef) = {
        ...(rest as V2FormComponentDef),
      };
      copySelectedItems.order.splice(index, 0, newName);
    },
    []
  );

  const removeItemFromGroup = (
    orders: string[],
    index: number,
    name: string,
    components: { [name: string]: V2FormComponentDef }
  ) => {
    orders.splice(index, 1);
    delete components[name];
  };

  const addModifiedItemToGroup = useCallback(
    (
      components: { [name: string]: V2FormComponentDef },
      orders: string[],
      index: number,
      newName: string,
      editedData: unknown
    ) => {
      const { name, ...rest } = editedData as V2FormComponentDefWithName;
      components[newName] = {
        ...(rest as V2FormComponentDef),
      };
      orders.splice(index, 0, newName);
    },
    []
  );

  const getEditedDef = useCallback(
    ({ editedData, oldName, newName, droppableId }: EditedDefData): V2FormTemplate | null => {
      const copySelectedItems = cloneDeep(formDefinition);
      if (oldName !== newName) {
        setFocusedItem(`${droppableId === 'form' ? '' : droppableId}${newName}`);
      }
      if (newName && oldName && droppableId) {
        if (droppableId === 'form') {
          const index = copySelectedItems.order.findIndex((o) => o === oldName);
          removeItemFromForm(index, oldName, copySelectedItems);
          addModifiedItemToForm(index, newName, copySelectedItems, editedData);
          return mapperRefChanger.renameFormReferences(copySelectedItems, oldName, newName);
        } else {
          const dropPath = droppableId.split('.');
          const finalComp = dropPath.reduce((acc: any, currPath: string) => {
            return (
              acc && acc.components && (acc.components[currPath] as V2GroupComponentDef).template
            );
          }, copySelectedItems) as V2FormTemplate;
          if (finalComp && finalComp.components[oldName]) {
            const dropOrder = finalComp.order;
            const dropComponents = finalComp.components;
            const index = dropOrder.findIndex((o) => o === oldName);
            removeItemFromGroup(dropOrder, index, oldName, dropComponents);
            addModifiedItemToGroup(dropComponents, dropOrder, index, newName, editedData);
            return mapperRefChanger.renameFormReferences(
              copySelectedItems,
              oldName,
              newName,
              droppableId
            );
          }
        }
      }
      return null;
    },
    [formDefinition, mapperRefChanger, addModifiedItemToForm, addModifiedItemToGroup]
  );

  const handleClickSimulate = async () => {
    if (isDirty || !isEqual(savedDefinition, formDefinition)) {
      const props = CONFIRMATION.simulate;
      const status = await openConfirmation(props);
      switch (status) {
        case 'confirm':
          save();
          break;
        case 'cancel':
          return;
        default:
          return;
      }
    }
    if (!isCreationCommand) {
      setShowSimulator(true);
    } else {
      setShowWorkflow(true);
    }
  };
  const { openRedirect } = useRedirect();

  const isTabDirty = () =>
    isDirty ||
    !isEqual(savedDefinition, formDefinition) ||
    !!initialMapperRef.current?.checkModelHasChanged() ||
    !!commandToEventMapperRef.current?.checkModelHasChanged();

  const onDiscardChanges = () => {
    setFormDefinition(savedDefinition);
    commandToEventMapperRef.current?.resetMapDiagram();
    initialMapperRef.current?.resetMapDiagram();
    resetCommandTypeConfig();
  };

  const handleChange = async (event: ChangeEvent<{}>, newValue: number) => {
    if (isTabDirty()) {
      const status = await openRedirect();
      switch (status) {
        case 'save':
          save();
          break;
        case 'discard':
          onDiscardChanges();
          break;
        case 'cancel':
          return;
      }
    }
    updateSelectedComponent();
    setActiveTab(newValue);
  };

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return currentLocation.pathname !== nextLocation.pathname && isTabDirty();
  });
  useRouteBlocker({ blocker, onSave: save, onDiscard: onDiscardChanges });

  return (
    <Box className={commonClasses.container}>
      <Box className={`${commonClasses.innerContainer} ${classes.innerContainer}`}>
        <>
          <Box className={[commonClasses.titleBarContainer, commonClasses.rootContainer].join(' ')}>
            <TGBreadCrumbs
              title="Commands"
              backPath={`${ROOT_RECORDS_ROUTE}${aggregate}/commands/${commandName}`}
              selectedItem={commandName}
            />
            <ConfigurationHeader
              name={commandName}
              versionNos={(getCommandVersions(aggrIndex, commandName) || []).map(
                (version) => version.version
              )}
              versionChangePath={`${ROOT_RECORDS_ROUTE}${aggregate}/commands/${commandName}/version/versionIndex`}
              selectedVersion={Number(commandVersion)}
              handleAddVersion={handleAddVersion}
              handleRemoveVersion={handleRemoveVersion}
              handleCancelChanges={handleCancelChanges}
              showImportButton={false}
              hideCancelButton={false}
              aggregate={aggregate}
              aggregateIndex={aggrIndex}
              schemaVersion={0}
              actualSchema={{}}
              save={save}
              showSimulateButton={commandType === 'FORM'}
              onSimulate={handleClickSimulate}
            />
          </Box>
        </>
        <CommandTypeConfiguration
          commandType={commandType}
          setCommandType={onChangeCommndTypeData(setCommandType)}
          isImportIntegration={isImportIntegration}
          setIsImportIntegration={onChangeCommndTypeData(setIsImportIntegration)}
          fileType={fileType}
          onChangeTextFileType={onChangeCommndTypeData(setFileType)}
          commandTypeOptions={commandType !== 'TIMER' ? commandTypeOptions : []}
          onChangeOption={onChangeOption}
          createCommandType={createCommandType}
          setCreateCommandType={onChangeCommndTypeData(setCreateCommandType)}
          onChangeText={onChangeText}
          emptyIsNull={emptyIsNull}
          setEmptyIsNull={setEmptyIsNull}
          resetFormDefinition={resetFormDefinition}
        />
        {commandTabs?.length > 0 && (
          <Box className={`${commonClasses.tabContainer} ${classes.tabContainer}`}>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="tabs"
              className={commonClasses.tabs}
            >
              {commandTabs.map((commandTab) => (
                <Tab
                  key={commandTab.label}
                  label={<Typography className={classes.label}>{commandTab.label}</Typography>}
                />
              ))}
            </Tabs>
            <Divider />
            <TabPanel value={activeTab} index={0} className={commonClasses.panelContainer}>
              {commandType === 'BUTTON' || commandType === 'TIMER' || (commandType === 'IMPORT' && isImportIntegration) ? (
                <CommandToEventMapper
                  {...{
                    mapping,
                    commandId: commandName,
                    aggregate,
                    formDefinition,
                    mapperRef: commandToEventMapperRef,
                  }}
                />
              ) : (
                commandType &&
                commandType.match(/FORM|IMPORT/) && (
                  <FormEditor
                    {...{
                      formDefinition,
                      setFormDefinition,
                      isImportCommand: isImportCommand,
                      isImport: commandType === 'IMPORT',
                      formEditorEditedData,
                      setFormEditorEditedData,
                      formEditorEditedComponent,
                      setFormEditorEditedComponent,
                      setFormComponentNameError,
                      getEditedDef,
                      setFocusedItem,
                      focusedItem,
                      setCurrentEditItem,
                      currentEditItem,
                      version,
                    }}
                  />
                )
              )}
            </TabPanel>

            <TabPanel value={activeTab} index={1} className={commonClasses.panelContainer}>
              <div className={classes.mapperContainer}>
                {mapperItems.map((mapper) => (
                  <MapperList
                    key={mapper.key}
                    localSchemas={getLocalSchema(stateSchema, formSchema)}
                    title={mapper.title}
                    data={mapper.data}
                    setData={(data) => mapper.action(data as NodeMapDefinition[])}
                    mapScenario={mapper.mapScenario}
                    containerStyle={classes.mapper}
                  />
                ))}
              </div>
            </TabPanel>
            <TabPanel value={activeTab} index={2} className={commonClasses.panelContainer}>
              <InitialDataMapper
                {...{
                  properties: config?.aggregates?.[aggrIndex].properties,
                  formDefinition,
                  mapperRef: initialMapperRef,
                }}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={3} className={commonClasses.panelContainer}>
              <CommandToEventMapper
                {...{
                  mapping,
                  aggregate,
                  formDefinition,
                  commandId: commandName,
                  mapperRef: commandToEventMapperRef,
                }}
              />
            </TabPanel>
          </Box>
        )}
      </Box>
      {showSimulator && (
        <WorkflowSimulatorModal
          aggregateType={aggregate}
          aggregateQueryKey={aggregateTypeName}
          aggregateLabelProperty={aggregateLabelProperty}
          onClose={() => setShowSimulator(false)}
          onRun={(aggregateId: string) => {
            setShowSimulator(false);
            setShowWorkflow(true);
            setSimulatorAggregateId(aggregateId);
          }}
        />
      )}
      {showWorkflow && (
        <WorkflowPageContainer
          onCancel={() => {
            setShowWorkflow(false);
            setSimulatorAggregateId(undefined);
          }}
          onDonePress={() => {
            setShowWorkflow(false);
            setSimulatorAggregateId(undefined);
          }}
          aggregateType={aggregate}
          targetAggregateId={simulatorAggregateId}
          creationAction={isCreationCommand}
          title={commandTitle || ''}
          command={{
            __typename: 'CommandReference',
            commandName,
            commandVersion: parseInt(commandVersion) + 1,
            isSynchronous: !!isSynchronous,
            hasNoAggregateIdCommand: createCommandType
              ? isCreationCommand && createCommandType === NON_AGGREGATE_KEY
              : false,
            confirmationText,
            limitMaxOccurrence: limitMaxOccurrence || undefined,
            command: {
              template: formDefinition,
            },
          }}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  innerContainer: {
    height: '100%',
  },
  tabContainer: {
    height: 'calc(100% - 155px)',
  },
  limitBox: {
    width: 54,
    '& .MuiInputBase-root': {
      width: 54,
      height: 30,
      position: 'relative',
      left: 38,
      top: 3,
      '& input': {
        width: 54,
        height: 30,
        fontSize: 14,
        paddingLeft: 10,
        paddingRight: 2,
      },
    },
  },
  confirmBox: {
    width: '90%',
    '& .MuiInputBase-root': {
      width: '100%',
      '& input': {
        width: '100%',
      },
    },
  },
  mapperContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '12px 24px',
    backgroundColor: colors.greySaturated,
  },
  mapper: {
    width: '49%',
  },
  label: {
    textTransform: 'none',
    fontSize: 15,
  },
  commandType: {
    fontSize: 14,
  },
}));

export default Configuration;
