"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.doNotSendList = void 0;
exports.doNotSendList = [
    'Accessing object of type ProjectPreference which has been invalidated or deleted',
    'Cannot access realm that has been closed.',
    'Network error: Network request failed',
    'Network error: Network error',
    'GraphQL error: Not Authorised!',
    'JSON Parse error: Unrecognized token',
    'Rebuild Pipeline error: Error: 14 UNAVAILABLE: Connection dropped',
    'Rebuild Pipeline error: Error: 1 CANCELLED: Call cancelled'
];
