import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core';

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    height: '100%',
    backgroundColor: 'transparent',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    '& .MuiCollapse-entered': {
      height: '100% !important',
      '& .MuiCollapse-wrapper': {
        '& div[role="region"]': {
          height: '100%',
        },
        height: '100%',
        '& .MuiAccordionDetails-root': {
          height: '100%',
        },
      },
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    flexDirection: 'row-reverse',
    gap: 10,
    marginBottom: -1,
    padding: 0,
    minHeight: 20,
    height: 0,
    '&$expanded': {
      minHeight: 20,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
  },
  expandIcon: {
    '&$expanded': {
      transform: 'rotate(90deg)',
    },
  },
  content: {
    margin: 0,
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    paddingLeft: 30,
    height: '100%',
  },
}))(MuiAccordionDetails);

export { Accordion, AccordionSummary, AccordionDetails };
