import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import React from 'react';
import styled from 'styled-components';
import { BadgeColors, colors } from 'utils/colors';

interface BadgeProps {
  controlDefinition: {
    repeats?: boolean;
    oneLine?: boolean;
    displayOptions?: NodeMapDefinition;
    conditionalMap?: NodeMapDefinition | undefined;
    includeWMS?: boolean;
    computedMap?: NodeMapDefinition | undefined;
  };
  showConditionalMap?: boolean;
}

const getBadgeColor: (controlType: string) => { color: string; background?: string } = (
  controlType
) => {
  switch (controlType) {
    case 'includeWMS':
      return {
        color: `${BadgeColors.steelBlue}`,
        background: `${BadgeColors.steelBlue15}`,
      };
    case 'oneLine':
      return {
        color: `${BadgeColors.cornflowerBlue}`,
        background: `${BadgeColors.cornflowerBlue15}`,
      };
    case 'displayOptions':
      return {
        color: `${BadgeColors.skyBlue}`,
        background: `${BadgeColors.skyBlue15}`,
      };
    case 'conditionalMap':
      return {
        color: `${BadgeColors.slateGray}`,
        background: `${BadgeColors.slateGray15}`,
      };
    case 'repeats':
      return {
        color: `${BadgeColors.sageGreen}`,
        background: `${BadgeColors.sageGreen15}`,
      };
    case 'computed':
      return {
        color: `${BadgeColors.lavenderPurple}`,
        background: `${BadgeColors.lavenderPurple15}`,
      };
    default:
      return { color: colors.gray };
  }
};

export const CustomPageBadges: React.FC<BadgeProps> = ({
  controlDefinition,
  showConditionalMap = false,
}) => {
  return (
    <Container>
      {controlDefinition.includeWMS && (
        <Badge {...getBadgeColor('includeWMS')}>
          <BadgeLabel {...getBadgeColor('includeWMS')}>Layers</BadgeLabel>
        </Badge>
      )}
      {controlDefinition.oneLine && (
        <Badge {...getBadgeColor('oneLine')}>
          <BadgeLabel {...getBadgeColor('oneLine')}>One Line</BadgeLabel>
        </Badge>
      )}
      {!showConditionalMap && Boolean(controlDefinition.displayOptions) && (
        <Badge {...getBadgeColor('displayOptions')}>
          <BadgeLabel {...getBadgeColor('displayOptions')}>Display Options</BadgeLabel>
        </Badge>
      )}
      {showConditionalMap && Boolean(controlDefinition.displayOptions) && (
        <Badge {...getBadgeColor('conditionalMap')}>
          <BadgeLabel {...getBadgeColor('conditionalMap')}>Conditional Map</BadgeLabel>
        </Badge>
      )}
      {controlDefinition.repeats && (
        <Badge {...getBadgeColor('repeats')}>
          <BadgeLabel {...getBadgeColor('repeats')}>Repeatable</BadgeLabel>
        </Badge>
      )}
      {controlDefinition.computedMap && (
        <Badge {...getBadgeColor('computed')}>
          <BadgeLabel {...getBadgeColor('computed')}>Computed</BadgeLabel>
        </Badge>
      )}
    </Container>
  );
};

const Badge = styled.div<{ color: string; background?: string }>`
  padding: 3px 9px;
  border-radius: 8px;
  background: ${(props) => (props.background ? props.background : 'transparent')};
  color: ${(props) => props.color};
  height: 18px;
`;

const BadgeLabel = styled.div<{ color?: string }>`
  color: ${(props) => props.color};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 9px;
`;
