import { CommandCollection, EventDefinition } from '@terragotech/gen5-config-lib';
import _ from 'lodash';
import { useState } from 'react';

const useSort = () => {
  const [isAsc, setIsAsc] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');

  const handleSort = (data: CommandCollection | { [name: string]: EventDefinition }) => {
    const order = isAsc ? 'desc' : 'asc';
    const resultData = _.chain(data)
      .toPairs()
      .map(([k, v]) => {
        return { k, v };
      })
      .orderBy(item => l(item.k), order)
      .reduce((a, c) => {
        a = { ...a, [c.k]: c.v };
        return a;
      }, {})
      .value();
    return resultData;
  };

  const l = (value: string) => _.toLower(value);

  const handleSearch = (values: string[]) => {
    return searchText?.length > 0 ? values.filter((o) => l(o).includes(l(searchText))) : values;
  };
  return { isAsc, handleSort, handleSearch, setSearchText, searchText, setIsAsc };
};

export default useSort;
