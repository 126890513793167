import { ForeignColumnInput } from './ForeignColumnInput';
import { ValidOptionsCustomInput, ValidOptions } from './ValidOptionsCustomInput';
import { ValidOptionsCustomComponent } from './ValidOptionsCustomComponent';
import { AggrPropertyRow } from '../../../utils/types';
import { AnchoredLineEndpointsInput } from './AnchoredLineEndpointsInput';
import { SimpleColumnList } from './SimpleColumnList';
import { TitleToolTip } from './TitleToolTip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose } from '@fortawesome/pro-regular-svg-icons';
import { colors } from 'utils/colors';
import { TGSelect } from 'views/components/formElements';
import { FormDataProps } from '../../../views/pages/records/AggregateProperties/ModalPropertyFields';
import pluralize from 'pluralize';
import { ONE_TO_MANY_RELATION } from '../../../utils/Utils';
import _ from 'lodash';

const UITYPE_LOOKUP = {
  '': '',
  'Symbol Key': 'Symbol Key',
  Title: 'Title',
  Hidden: 'Hidden',
  DateTime: 'DateTime',
  Date: 'Date',
  JSON: 'JSON',
};
interface rowDataProps {
  includeInFullText: boolean;
  editable?: boolean;
  indexed?: boolean;
  nullable?: boolean;
  excludeFromMobile?: boolean;
  filterable?: boolean;
  hideFromAssetAttributes?: boolean;
}
//Missing columns:
// - description? - string
// - initValue? - null | any
const iconRender = (value: boolean) => {
  return (
    <FontAwesomeIcon
      icon={value ? faCheck : faClose}
      color={value ? colors.ChateauGreen : colors.red}
      style={styles.icon}
    />
  );
};
export const aggregatePropertiesTableColumns = (additionalTypes: object): object[] => [
  // {
  //   title: (
  //     <TitleToolTip
  //       minWidth={75}
  //       title="Order"
  //       tooltipText="Determines the order that properties are shown in the Table and Attributes Card"
  //     />
  //   ),
  //   field: 'order',
  //   editable: 'never',
  // },
  {
    title: (
      <TitleToolTip
        minWidth={75}
        title="Name"
        tooltipText={
          <>
            <p>Property name used on the back-end. Must be in camelCase.</p>
            <p>
              Exceptions to avoid:
              <ul style={{ paddingLeft: 20 }}>
                <li>ID -Camel case “Id” is OK (though should probably be avoided if possible) </li>
                <li>lastUpdated</li>
                <li>nodeId</li>
                <li>modifiedBy</li>
              </ul>
            </p>
          </>
        }
      />
    ),
    label: 'Name',
    validate: (rowData: FormDataProps) => {
      const name = rowData.name as string;
      const relation = rowData.relation as string;
      return _.isUndefined(name) || _.isEmpty(name)
        ? { isValid: false, helperText: 'Name cannot be empty' }
        : name.match(new RegExp(/^[a-z]/)) === null
        ? { isValid: false, helperText: 'First char of name cannot be uppercase.' }
        : relation && relation === ONE_TO_MANY_RELATION && !pluralize.isPlural(name)
        ? { isValid: false, helperText: 'Name cannot be singular.' }
        : true;
    },
    field: 'name',
    mOrder: 1,
    sort: 1,
  },
  {
    title: (
      <TitleToolTip
        title="Label"
        minWidth={75}
        tooltipText="Property name visible on HMI.  No restrictions."
      />
    ),
    field: 'label',
    label: 'Label',
    validate: (rowData: FormDataProps) => {
      const label = rowData.label as string;
      return _.isUndefined(label) || _.isEmpty(_.trim(label))
        ? { isValid: false, helperText: 'Label cannot be empty' }
        : true;
    },
    mOrder: 2,
    sort: 2,
  },
  {
    title: (
      <TitleToolTip
        title="Type"
        minWidth={75}
        tooltipText={
          <>
            <p>The type of data that is being stored in this property</p>
            <p>
              Options:
              <ul style={{ paddingLeft: 20 }}>
                <li>ID</li>
                <li>String (255 "max")</li>
                <li>Int</li>
                <li>Float</li>
                <li>DateTime</li>
                <li>Date</li>
                <li>Boolean</li>
                <li>JSON</li>
                <li>Geography</li>
                <li>PhotoCollection</li>
                <li>FileCollection</li>
                <li>Anchored Line</li>
                <li>Project</li>
                <li>Issue</li>
                <li>Light</li>
                <li>Folder</li>
              </ul>
            </p>
          </>
        }
      />
    ),
    field: 'type',
    label: 'Type',
    validate: (rowData: FormDataProps) => {
      const type = rowData.type as string;
      return _.isUndefined(type) || _.isEmpty(type)
        ? { isValid: false, helperText: 'Type cannot be empty' }
        : true;
    },
    option: {
      ID: 'ID',
      String: 'String',
      Int: 'Int',
      Float: 'Float',
      DateTime: 'DateTime',
      Date: 'Date',
      Boolean: 'Boolean',
      JSON: 'JSON',
      Geography: 'Geography',
      PhotoCollection: 'PhotoCollection',
      FileCollection: 'FileCollection',
      AnchoredLine: 'AnchoredLine',
      ...additionalTypes,
    },
    mOrder: 3,
    sort: 3,
  },
  {
    title: (
      <TitleToolTip
        title="Nullable"
        minWidth={75}
        tooltipText="Allow a null property on the back-end.  Typically should be set to true"
      />
    ),
    field: 'nullable',
    label: 'Nullable',
    type: 'boolean',
    align: 'center',
    initialEditValue: true,
    render: (rowdata: rowDataProps) => iconRender(rowdata.nullable as boolean),
    gridView: true,
    gOrder: 1,
    sort: 4,
  },
  {
    title: (
      <TitleToolTip
        title="Filterable"
        minWidth={90}
        tooltipText="Allow filtering on both web and mobile filters"
      />
    ),
    field: 'filterable',
    label: 'Filterable',
    type: 'boolean',
    align: 'center',
    render: (rowdata: rowDataProps) => iconRender(rowdata.filterable as boolean),
    gridView: true,
    gOrder: 2,
    sort: 5,
  },
  {
    title: (
      <TitleToolTip
        title="Editable"
        minWidth={75}
        tooltipText="Allow editing on the web table view"
      />
    ),
    field: 'editable',
    label: 'Editable',
    type: 'boolean',
    align: 'center',
    render: (rowdata: rowDataProps) => iconRender(rowdata.editable as boolean),
    gridView: true,
    gOrder: 3,
    sort: 6,
  },
  {
    title: (
      <TitleToolTip
        title="Hide From Asset Attributes"
        minWidth={150}
        tooltipText={
          <>
            <p>Hidden on the Attributes Card</p>
            <p>
              Note: if a property does not have a value, it is already hidden from the Attributes
              Card
            </p>
          </>
        }
      />
    ),
    field: 'hideFromAssetAttributes',
    label: 'Hide From Asset Attributes',
    type: 'boolean',
    align: 'center',
    render: (rowdata: { hideFromAssetAttributes: boolean }) =>
      iconRender(rowdata.hideFromAssetAttributes),
    gridView: true,
    gOrder: 4,
    sort: 7,
  },
  {
    title: (
      <TitleToolTip
        title="Include In Full Text"
        minWidth={150}
        tooltipText="  Whether or not to include this field in the full text search index. This index is used when typing in the free form search bar at the top of the mobile list view, or in the free form search bar at the top of the table on web"
      />
    ),
    field: 'includeInFullText',
    label: 'Include in Full Text',
    type: 'boolean',
    align: 'center',
    render: (rowdata: rowDataProps) => iconRender(rowdata.includeInFullText as boolean),
    gridView: true,
    gOrder: 5,
    sort: 8,
  },
  {
    title: (
      <TitleToolTip
        title="Exclude From Mobile"
        minWidth={150}
        tooltipText="  Whether or not to exclude this field when syncing data to mobile"
      />
    ),
    field: 'excludeFromMobile',
    label: 'Exclude From Mobile',
    type: 'boolean',
    align: 'center',
    render: (rowdata: rowDataProps) => iconRender(rowdata.excludeFromMobile as boolean),
    gridView: true,
    gOrder: 5,
    sort: 8,
  },
  {
    title: (
      <TitleToolTip
        title="Indexed"
        minWidth={75}
        tooltipText="Whether the database should optimize for searches of this particular field. This will mostly impact mobile filtering performance when filtering against this field, and backend integration performance if the integration will be searching against this particular field"
      />
    ),
    field: 'indexed',
    label: 'Indexed',
    type: 'boolean',
    align: 'center',
    render: (rowdata: rowDataProps) => iconRender(rowdata.indexed as boolean),
    gridView: true,
    gOrder: 6,
    sort: 9,
  },
  {
    title: (
      <TitleToolTip
        title="Filter Options"
        minWidth={120}
        tooltipText="  ‘DISTINCT’ is the only filter type currently available. Future plans include things like ranges for number fields, or categories. DISTINCT here means to show all known unique values on the filter page"
      />
    ),
    field: 'filterOptions',
    label: 'FilterOptions',
    option: {
      DISTINCT: 'DISTINCT',
    },
    mOrder: 6,
    sort: 10,
  },
  {
    title: (
      <TitleToolTip
        title="Relation"
        minWidth={75}
        tooltipText={
          <>
            <p>Describes the relationship of this property to another</p>
            <p>
              <ul style={{ paddingLeft: 20 }}>
                <li>
                  One-to-One relationships
                  <ul>
                    <li>Should be linked One-to-One bidirectional</li>
                  </ul>
                </li>
                <li>
                  One-to-Many relationships
                  <ul>
                    <li>On the One side</li>
                    <ul>
                      <li>“Name” column as plural. For example: Pole properties fixtures</li>
                      <li>Relation: One-to-Many</li>
                      <li>Foreign Column should point to the Many side. For example: pole</li>
                    </ul>
                    <li>On the Many side</li>
                    <ul>
                      <li>Relation: One-to-One</li>
                    </ul>
                  </ul>
                  <li>Many-to-Many (Deprecated)</li>
                </li>
              </ul>
            </p>
          </>
        }
      />
    ),
    field: 'relation',
    label: 'Relation',
    validate: (rowData: FormDataProps) => {
      const relation = rowData.relation as string;
      return _.isUndefined(relation) || _.isEmpty(relation)
        ? { isValid: false, helperText: 'Relation cannot be empty' }
        : true;
    },
    export: false,
    option: {
      'ONE-TO-MANY': 'ONE-TO-MANY',
      'ONE-TO-ONE': 'ONE-TO-ONE',
      // 'MANY-TO-MANY': 'MANY-TO-MANY', TODO: Add Many to Many back in when it's needed
    },
    mOrder: 7,
    sort: 11,
    canShow: false,
  },
  {
    title: (
      <TitleToolTip
        title="Foreign Column"
        minWidth={100}
        tooltipText="Use only in correlation with the One-to-Many relationship. Ignored in all other cases"
      />
    ),
    field: 'foreignColumn',
    label: 'Foreign Column',
    export: false,
    editComponent: ({
      rowData,
      onChange,
    }: {
      rowData: AggrPropertyRow;
      onChange: (value: string | undefined) => void;
    }) => <ForeignColumnInput row={rowData} onChange={onChange} />,
    canShow: {
      key: 'relation',
      value: 'ONE-TO-MANY',
    },
    mOrder: 8,
    sort: 12,
  },
  {
    title: (
      <TitleToolTip
        title="Valid Options"
        tooltipText="A list of values that this property may contain.  This list is shown as radio button options when editing this property. This only applies when directly editing the field on the web table. It is not used to limit the values that can be provided through mapping"
      />
    ),
    field: 'validOptions',
    label: 'Valid Options',
    editComponent: ({
      value,
      onChange,
    }: {
      value: ValidOptions | undefined;
      onChange: (value: ValidOptions) => void;
    }) => <ValidOptionsCustomInput validOptions={value} setValidOptions={onChange} />,
    render: (props: { validOptions: ValidOptions | undefined }) => {
      if (!props.validOptions) {
        props.validOptions = undefined;
      }
      return <ValidOptionsCustomComponent validOptions={props.validOptions} />;
    },
    mOrder: 10,
    sort: 13,
  },
  {
    title: 'Line Start',
    field: 'lineStart',
    label: 'Line Start',
    editComponent: ({
      value,
      onChange,
      rowData,
    }: {
      value: string[] | undefined;
      onChange: (value: string[] | undefined) => void;
      rowData: AggrPropertyRow;
    }) =>
      rowData.type === 'AnchoredLine' ? (
        <AnchoredLineEndpointsInput row={rowData} onChange={onChange} value={value} type='lineStart'/>
      ) : null,
    render: ({ lineStart }: { lineStart?: string[] }) => <SimpleColumnList list={lineStart} />,
    mOrder: 4,
    sort: 14,
    canShow: {
      key: 'type',
      value: 'AnchoredLine',
    },
  },
  {
    title: 'Line End',
    field: 'lineEnd',
    label: 'Line End',
    editComponent: ({
      value,
      onChange,
      rowData,
    }: {
      value: string[] | undefined;
      onChange: (value: string[] | undefined) => void;
      rowData: AggrPropertyRow;
    }) =>
      rowData.type === 'AnchoredLine' ? (
        <AnchoredLineEndpointsInput row={rowData} onChange={onChange} value={value} type='lineEnd'/>
      ) : null,
    render: ({ lineEnd }: { lineEnd?: string[] }) => <SimpleColumnList list={lineEnd} />,
    mOrder: 5,
    sort: 15,
    canShow: {
      key: 'type',
      value: 'AnchoredLine',
    },
  },
  {
    title: (
      <TitleToolTip
        title="UI Type"
        tooltipText={
          <>
            <p>Determines how to represent the property on the web table.</p>
            <p>
              <ul style={{ paddingLeft: 20 }}>
                <li>Symbol Key - default type to indicate symbology</li>
                <li>Title - Allows access to the Attributes Card via the :eye: symbol</li>
                <li>Hidden - Hidden from the table column</li>
                <li>
                  DateTime - Ensures that the Date string in the property is displayed in the user’s
                  current timezone. Also ensures the date time editor is used when editing the field
                </li>
                <li>Date - Displays the Date exactly as entered. Uses date editor when editing field.</li>
                <li>JSON - Deprecated. Currently does the same thing as Hidden</li>
              </ul>
            </p>
          </>
        }
      />
    ),
    field: 'uiType',
    label: 'UI Type',
    editComponent: ({
      value,
      onChange,
      rowData,
    }: {
      value: string;
      onChange: (value: unknown) => void;
      rowData: AggrPropertyRow;
    }) => {
      return (
        <TGSelect
          {...{
            id: 'Geography',
            value:
              rowData.type === 'Geography'
                ? 'JSON'
                : value
                ? value
                : Object.values(UITYPE_LOOKUP)[0],
            options: Object.values(
              Object.fromEntries(
                Object.entries(UITYPE_LOOKUP).filter(([key, value]) => key !== '' && value !== '')
              )
            ),
            onChange,
            containerCss: styles.uiType,
            type: 'UIType',
            canShowEmpty: true,
          }}
        />
      );
    },
    option: { ...UITYPE_LOOKUP },
    mOrder: 9,
    sort: 16,
  },
];

const styles = {
  uiType: {
    marginTop: 10,
  },
  icon: {
    fontSize: 20,
    position: 'relative' as const,
    left: -8,
  },
};
