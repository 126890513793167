import React, { useState, useMemo, useCallback } from 'react';
import { initFormRootRenderer, OnSubmit } from '@terragotech/form-renderer';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import {
  ControlProvider,
  FieldHeader,
  AlertDialog,
  AlertDialogProps,
  useConfig as useWebUIConfig,
  InfoBarCollection,
  InfoBarTypeEnum,
  InfoBar,
  useAuthContext,
  useUserInfo,
  useCurrentLocation,
  useTargetAggregate,
  useOnlineDataLookup,
  useSpinner,
  getErrorsAndWarnings,
  useOnlineProximityLookup,
  useOnlineUserLookup,
} from '@terragotech/gen5-shared-components';
import { CommandInstanceType, CommandResult } from '@terragotech/gen5-command-processing-lib';
import CircularProgress from '@material-ui/core/CircularProgress';
import { v4 } from 'uuid';
import { useStyles } from './WorkflowPage.styles';
import {
  WorkflowPageProps,
  ErrorsAndWarnings,
  FormRendererWrapperProps,
} from './WorkflowPage.types';
import { processCommand } from './CommandProcessor';
import JSONTree from 'react-json-tree';
import { useAdvancedSearch } from '../../graphql/hooks/useAdvancedSearch';

const FormRootRendererInitiated = initFormRootRenderer(ControlProvider);

const WorkflowPage: React.FC<WorkflowPageProps> = (props) => {
  const {
    config,
    openWorkflow,
    onCancelPress,
    command: commandAction,
    targetAggregateId,
    onDonePress,
    aggregateType,
    title,
    creationAction,
  } = props;
  const [timeOpened] = React.useState(new Date()); // we collect this data for analytics

  const { functionDefinitions } = useWebUIConfig();
  const userInfo = useUserInfo();
  const authContextInfo = useAuthContext();
  const bestLocation = useCurrentLocation(); // best location is used to determine proximity
  const onlineDataLookup = useOnlineDataLookup();
  const onlineProximityLookup = useOnlineProximityLookup();
  const onlineUserLookup = useOnlineUserLookup();

  const { error, loading, record } = useTargetAggregate(
    aggregateType,
    targetAggregateId ? targetAggregateId : []
  );

  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [alertDialog, setAlertDialog] = useState<AlertDialogProps | null>(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState<CommandResult | null>(null);
  const { toggleSpinner } = useSpinner();
  const advancedSearch = useAdvancedSearch()

  const styles = useStyles();

  const dataMapping = useMemo(() => {
    const {
      roles,
      roleString,
      permissionString,
      permissions,
      username,
      email,
      firstName,
      lastName,
    } = userInfo;
    return {
      accessors: {
        ONLINE_ATTRIB_LOOKUP: onlineDataLookup,
        ADVANCED_SEARCH: advancedSearch,
        METADATA: () => {
          return {
            isOnline: true,
            timeFormOpened: timeOpened,
            latitude: bestLocation?.latitude,
            longitude: bestLocation?.longitude,
            locationAccuracy: bestLocation?.accuracy,
            userInfo: {
              userName: username,
              email: email,
              familyName: lastName,
              givenName: firstName,
              roles,
              roleString,
              authPayload: authContextInfo,
              authExpiration: authContextInfo && authContextInfo.authExpiration,
              permissionString,
              permissions,
            },
            selectedProjects: [],
          };
        },
        STATE: () => record || {},
        ONLINE_PROXIMITY_LOOKUP: onlineProximityLookup,
        ONLINE_USER_LOOKUP: onlineUserLookup,
      },
    };
  }, [
    onlineDataLookup,
    onlineProximityLookup,
    onlineUserLookup,
    record,
    bestLocation,
    timeOpened,
    userInfo,
    authContextInfo,
    advancedSearch
  ]);

  const handleFormSubmission = useCallback<OnSubmit>(
    async (args) => {
      const { data } = args;
      if (!creationAction && (!record || Array.isArray(record))) {
        return;
      }
      const {
        token,
        ready,
        clearToken,
        loadExistingAuth,
        loginSuccess,
        authExpiration,
        ...restAuthInfo
      } = authContextInfo;
      const command: CommandInstanceType = {
        target: creationAction ? v4() : !record || Array.isArray(record) ? '' : record.id,
        aggregateType: aggregateType,
        version: commandAction.commandVersion,
        data,
        timestamp: new Date(),
        type: commandAction.commandName,
        securityInfo: {
          userName: authContextInfo.username ?? '',
          clientId: 'APPLICATION_ID',
          roles: authContextInfo.permissionStatus ? [authContextInfo.permissionStatus] : [],
          authPayload: restAuthInfo,
        },
      };

      try {
        setSubmitting(true);
        setSubmitError('');
        toggleSpinner(true);
        const commandResult = await processCommand(
          config,
          onlineDataLookup,
          onlineProximityLookup,
          onlineUserLookup,
          command,
          advancedSearch
        );
        setShowResult(true);
        setResult(commandResult);
      } catch (e) {
        setSubmitError(e?.toString() || 'An error occurred while processing the command.');
      }
      toggleSpinner(false);
      setSubmitting(false);
    },
    [
      toggleSpinner,
      config,
      onlineDataLookup,
      onlineProximityLookup,
      onlineUserLookup,
      record,
      commandAction,
      aggregateType,
      creationAction,
      authContextInfo,
      advancedSearch
    ]
  );

  const setErrorAlert = (title: string) => {
    setAlertDialog({
      title: title,
      cancelText: 'Cancel',
    });
  };
  const setWarningAlert = () => {
    setAlertDialog({
      title: 'Warning!',
      cancelText: 'Cancel',
      okText: 'Continue anyway',
    });
  };
  const getBars = (errors: any, warnings: any, fieldWarnings: any) => {
    const bars: InfoBar[] = [];
    if (!isEmpty(errors)) {
      Object.keys(errors).map((key) =>
        bars.push({ title: 'Error!', message: errors[key], type: InfoBarTypeEnum.ERROR })
      );
    }
    if (!isEmpty(warnings)) {
      Object.keys(warnings).map((key) =>
        bars.push({
          title: 'Form Level Warning!',
          message: warnings[key],
          type: InfoBarTypeEnum.WARNING,
        })
      );
    }
    return bars;
  };

  const renderContent = (errorsAndWarnings: ErrorsAndWarnings) => {
    const { formErrors, fieldErrors, formWarnings, fieldWarnings } = errorsAndWarnings;

    // Build a list of errors & warnings to show to the user if there's a problem when they click "done"
    let alertMessages: string[] = [];
    const formHasErrors = !!formErrors.length || !!fieldErrors.length;

    // if we have errors then show those errors
    if (formHasErrors) {
      alertMessages = [...formErrors, ...fieldErrors];
    }

    // if we have no errors but we have warnings then show those warnings
    if (!formHasErrors) {
      alertMessages = [...formWarnings, ...fieldWarnings];
    }

    if (!alertDialog) {
      return null;
    }

    return (
      <DialogContent style={{ marginBottom: 4 }}>
        {[...alertMessages].map((value, index) => (
          <DialogContentText className={styles.alertText} key={index}>
            - {value}
          </DialogContentText>
        ))}
      </DialogContent>
    );
  };

  return (
    <>
      <Dialog open={openWorkflow} maxWidth={'xl'} classes={{ paper: styles.dialogPaper }}>
        {error && <div>Failed to load asset.</div>}
        {loading && (
          <div className={styles.loadingContainer}>
            <CircularProgress color="primary" />
          </div>
        )}
        {!loading && commandAction.command && (
          <FormRootRendererInitiated
            onSubmit={handleFormSubmission}
            dataMappingContext={dataMapping}
            template={commandAction.command.template}
            functionDefinitions={functionDefinitions ?? {}}
            render={({
              handleSubmit,
              renderForm,
              formChanged,
              errors,
              warnings,
              fieldLevelWarnings,
            }) => {
              const { formErrors, fieldErrors, formWarnings, fieldWarnings } = getErrorsAndWarnings(
                errors,
                warnings,
                fieldLevelWarnings
              );

              return (
                <FormRendererWrapper
                  submitting={submitting}
                  title={title}
                  onDonePress={() => {
                    if (formErrors.length > 0 || fieldErrors.length > 0) {
                      handleSubmit();
                      return setErrorAlert('Error');
                    } else if (formWarnings.length > 0 || fieldWarnings.length > 0) {
                      return setWarningAlert();
                    } else if (commandAction.confirmationText) {
                      return setConfirmationDialogOpen(true);
                    }
                    handleSubmit();
                  }}
                  onCancelPress={onCancelPress}
                  canSave={true}
                  bars={getBars(formErrors, formWarnings, fieldWarnings)}
                >
                  {renderForm()}
                  {alertDialog && (
                    <AlertDialog
                      title={alertDialog.title}
                      okText={alertDialog.okText}
                      cancelText={alertDialog.cancelText}
                      onOkPress={
                        isEmpty(errors)
                          ? () => {
                              if (commandAction.confirmationText) {
                                return setConfirmationDialogOpen(true);
                              } else {
                                return handleSubmit();
                              }
                            }
                          : undefined
                      }
                      onCancelPress={() => {
                        setAlertDialog(null);
                      }}
                    >
                      {renderContent({
                        formErrors,
                        fieldErrors,
                        formWarnings,
                        fieldWarnings,
                      })}
                    </AlertDialog>
                  )}
                  {confirmationDialogOpen && (
                    <AlertDialog
                      title={'Are you sure?'}
                      okText={'Continue'}
                      cancelText={'Cancel'}
                      onOkPress={handleSubmit}
                      onCancelPress={() => setConfirmationDialogOpen(false)}
                    >
                      {commandAction.confirmationText}
                    </AlertDialog>
                  )}
                </FormRendererWrapper>
              );
            }}
          />
        )}
      </Dialog>
      <Dialog open={showResult}>
        <DialogTitle>Result Event(s)</DialogTitle>
        <DialogContent>
          {!submitting && !submitError && (
            <JSONTree data={result || {}} shouldExpandNode={() => true} hideRoot={true} />
          )}
          {!submitting && submitError && <DialogContentText>{submitError}</DialogContentText>}
        </DialogContent>
        {!submitting && (
          <DialogActions>
            <Button focusRipple={false} onClick={() => onDonePress()} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export const FormRendererWrapper: React.FC<FormRendererWrapperProps> = ({
  title,
  children,
  canSave,
  onDonePress,
  onCancelPress,
  submitting,
  bars,
}) => {
  const wrapperStyles = useStyles();
  return (
    <div className={wrapperStyles.wrapperContainer}>
      <FieldHeader
        title={title}
        canSave={canSave}
        submitting={submitting}
        onDonePress={onDonePress}
        onCancelPress={onCancelPress}
      />
      <InfoBarCollection bars={bars} fullwidth={false} />
      <div className={wrapperStyles.content}>{children}</div>
    </div>
  );
};

export default WorkflowPage;
