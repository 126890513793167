import { FormControl } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { TGSelect } from '../../views/components/formElements';
import { ScheduleActionRow } from '@terragotech/gen5-config-lib';
import { TimerRepeatCustomSelect } from './TimerRepeatCustomSelect';

interface TimerRepeatSelectProps {
  row: ScheduleActionRow;
  onChange: (value: string) => void;
}

export const TimerRepeatSelect = ({ row, onChange }: TimerRepeatSelectProps) => {
  const [repeat, setRepeat] = useState(
    row.repeat ? (row.repeat === 'Daily' ? 'Daily' : 'Custom') : 'Custom'
  );

  useEffect(() => {
    if (repeat === 'Daily') {
      if (row.endTime) {
        row.endTime = undefined;
      }
    }
  }, [repeat, setRepeat, row, onChange]);

  const handleChange = (value: string) => {
    setRepeat(value);
    onChange(value);
  };

  const styles = {
    formContainer: {
      minWidth: 120,
      marginTop: 10,
    },
  };

  return (
    <FormControl variant="outlined" style={styles.formContainer}>
      <TGSelect
        id="Repeat"
        value={repeat}
        options={['Daily', 'Custom']}
        onChange={handleChange}
        type="Repeat"
      />
      {repeat && repeat !== 'Daily' && <TimerRepeatCustomSelect row={row} onChange={onChange} />}
    </FormControl>
  );
};
