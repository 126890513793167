import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'views/pages/records/components/common/Accordions';

interface Props {
  header: React.ReactNode;
  body: React.ReactNode;
}

const AccordionView = ({ header, body }: Props) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const onToggleExpand = () => {
    setIsExpanded((e) => !e);
  };
  return (
    <Accordion expanded={isExpanded}>
      <AccordionSummary
        onClick={onToggleExpand}
        expandIcon={<FontAwesomeIcon icon={faChevronRight} className={classes.icon} />}
      >
        {header}
      </AccordionSummary>
      <AccordionDetails>{body}</AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 20,
    height: 20,
  },
}));

export default AccordionView;
