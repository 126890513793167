import { V2PageTemplate, V2PageComponentDef, V2ChartComponent } from '@terragotech/page-renderer';
import { JSONSchema6 } from 'json-schema';

type TSEnum = string[];
interface TSObject {
  [key: string]: TSType;
}

type TSType = TSEnum | TSObject | 'string';

const CartesianChartData: JSONSchema6 = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      id: { type: ['string', 'number'] },
      data: {
        description: 'serieData',
        type: 'array',
        items: {
          type: 'object',
          properties: {
            x: {
              anyOf: [{ type: ['number', 'string'] }, { type: 'string', format: 'date' }],
            },
            y: {
              anyOf: [{ type: ['number', 'string'] }, { type: 'string', format: 'date' }],
            },
          },
          required: ['x', 'y'],
        },
      },
    },
    required: ['id', 'data'],
  },
};

export const getChartObjectFromChartType = (
  chartType: V2ChartComponent['chartType'],
  elementName: string
): JSONSchema6 => {
  switch (chartType) {
    case 'bar':
      return {
        type: 'object',
        properties: {
          data: {
            type: 'array',
            items: { type: 'object', properties: {} },
          },
          indexBy: { type: 'string' },
          keys: {
            type: 'array',
            items: { type: 'string' },
          },
          groupMode: { type: 'string', enum: ['grouped', 'stacked'] },
          layout: { type: 'string', enum: ['horizontal', 'vertical'] },
        },
        required: ['data'],
      };
    case 'line':
      return {
        type: 'object',
        properties: {
          data: CartesianChartData,
          curve: {
            type: 'string',
            enum: [
              'basis',
              'cardinal',
              'catmullRom',
              'linear',
              'monotoneX',
              'monotoneY',
              'natural',
              'step',
              'stepAfter',
              'stepBefore',
            ],
          },
          layers: {
            type: 'array',
            items: {
              type: 'string',
              enum: [
                'grid',
                'markers',
                'axes',
                'areas',
                'crosshair',
                'lines',
                'points',
                'slices',
                'mesh',
                'legends',
              ],
            },
          },
        },
        required: ['data'],
      };
    case 'scatterPlot':
      return {
        type: 'object',
        properties: {
          data: CartesianChartData,
          layers: {
            type: 'array',
            items: {
              type: 'string',
              enum: ['grid', 'axes', 'nodes', 'markers', 'mesh', 'legends', 'annotations'],
            },
          },
        },
        required: ['data'],
      };
    case 'pie':
      return {
        type: 'object',
        properties: {
          data: {
            // Chart data, which should be immutable. Immutability of the data is important as re-computations depends on it.
            type: 'array',
            items: {
              type: 'object',
              properties: {
                id: { type: ['string', 'number'] },
                value: { type: 'number' },
              },
            },
          },
          id: { description: 'idKey', type: 'string' },
          value: { description: 'valueKey', type: 'string' },
          sortByValue: { type: 'boolean', enum: [true, false] },
          layers: {
            type: 'array',
            items: { type: 'string', enum: ['arcs', 'arcLinkLabels', 'arcLabels', 'legends'] },
          },
        },
        required: ['data'],
      };
    default:
      console.log(
        `Missing schema for chart type ${chartType ?? '(none)'} on element ${
          elementName || '(no name)'
        }.`
      );
      return {};
  }
};

export const getPropertyObjectFromPageComponent = (
  PageComponent: V2PageComponentDef,
  elementName: string
): JSONSchema6 => {
  const { type } = PageComponent;
  switch (type) {
    case 'text':
      return { type: 'string' };
    case 'aggregateMap':
      return {
        type: 'object',
        properties: {
          value: {
            type: 'string',
          },
          values: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
          lat: {
            type: 'number',
          },
          lon: {
            type: 'number',
          },
          zoom: {
            type: 'number',
          },
        },
      };
    case 'image':
      return {
        type: 'array',
        items: {
          type: 'string',
          format: 'photo',
        },
      };
    case 'file':
      return {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            name: { type: 'string' },
            id: { type: 'string' },
          },
        },
      };
    case 'streetView':
      return {
        type: 'object',
        properties: {
          lat: { type: 'number' },
          lon: { type: 'number' },
        },
      };
    case 'number':
    case 'date':
      return { type: 'string', format: 'date' };
    case 'time':
      return { type: 'number' };
    case 'datetime':
      return { type: 'string', format: 'date-time' };
    case 'chart':
      return getChartObjectFromChartType(PageComponent.chartType, elementName);
    case 'list':
      return {
        type: 'object',
        properties: {
          values: {
            type: 'array',
            items: {
              type: 'object',
              properties: {},
            },
          },
        },
      };
    default:
      console.log(
        `Missing schema for type ${type ?? '(none)'} on element ${elementName || '(no name)'}.`
      );
      return {};
  }
};

export const convertV2PageTemplateToJsonSchema = (v2PageTemplate: V2PageTemplate): JSONSchema6 => {
  const properties: { [k: string]: JSONSchema6 } = {};
  // now we're going to populate the data object with the page template
  v2PageTemplate &&
    v2PageTemplate.elements &&
    Object.entries(v2PageTemplate.elements).forEach(([key, e]): void => {
      const PageComponent = e.component;
      // don't process textheaders, and at least for now, functions
      if (PageComponent) {
        let property: JSONSchema6;
        // if it's a group, recurse and get all of its interfaces
        if (PageComponent.type === 'group') {
          property = convertV2PageTemplateToJsonSchema(PageComponent.template);
          if (PageComponent.repeats) {
            properties[key] = {
              type: 'array',
              items: convertV2PageTemplateToJsonSchema(PageComponent.template),
            };
          } else {
            properties[key] = convertV2PageTemplateToJsonSchema(PageComponent.template);
          }
        }
        // if a regular component, add it to the current interface, unless it's a textheader or function(no types for those)
        else {
          property = getPropertyObjectFromPageComponent(PageComponent, key);
        }

        if (
          //general case for all other repeats
          'repeats' in PageComponent &&
          PageComponent.repeats
        ) {
          properties[key] = { type: 'array', items: property };
          // } else if (
          //   PageComponent.type === 'location' &&
          //   PageComponent?.repeats?.enabled === false &&
          //   PageComponent?.multiplePoints?.enabled &&
          //   (PageComponent?.multiplePoints?.maximum > 1 ||
          //     PageComponent?.multiplePoints?.unlimited === true)
          // ) {
          //   //special case for multiple points (array) on location type
          //   properties[key] = { type: 'array', items: property };
        } else {
          properties[key] = property;
        }
      }
    });

  return {
    type: 'object',
    properties,
  };
};
