import { Box, createStyles, makeStyles, TextField, Theme, InputAdornment,
} from '@material-ui/core'; // Adjust based on your UI library
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { colors } from 'utils/colors';

export interface SearchBarProps {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
}

export const SearchBar = ({ 
    value,
    onChange,
    placeholder,
    }: SearchBarProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.search}>
            <TextField
                type="search"
                className={classes.input}
                placeholder={placeholder || 'Search'}
                onChange={(e) => onChange && onChange(e.target.value ? e.target.value : '')}
                focused
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faSearch} className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
            />
        </Box>
    );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      borderBottom: `1px solid ${colors.black10}`,
    },
    input: {
      position: 'relative',
      width: '100%',
      variant: 'outlined',
      '& .MuiInputBase-input': {
        backgroundColor: colors.white10,
        padding: '12px 25px 12px 48px',
        fontSize: 14,
      },
    },
    searchIcon: {
      position: 'absolute',
      left: 25,
      fontSize: 16,
      zIndex: 2,
      color: colors.black30,
    },
  })
);

