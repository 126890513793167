import { isEmpty } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import {
  MenuItem,
  Icon,
  Select,
  Typography,
  InputAdornment,
  ListSubheader,
  TextField,
  makeStyles,
  Divider,
  alpha,
  Theme,
  createStyles,
  Button,
} from '@material-ui/core';
import Placeholder from 'common/PlaceHolder';
import { FontIconOption, styles, getAllfontIcons, getFAIconName } from './jsonPartsGenerators';
import { colors } from './colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faClose } from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';

const offset = 73;

export const IconPicker = (
  iconValue: string | undefined,
  onChange: (value: string | undefined) => void
) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  let top = 400;
  let selectedOption: FontIconOption = {
    value: iconValue ? `fa-solid ${iconValue}` : '',
    label: (
      <i className={`fa-solid ${iconValue}`} style={{ ...styles.icon, ...styles.checkIcon }} />
    ),
  };
  const allIcons = getAllfontIcons();
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const filteredIcons = useMemo(() => {
    if (isEmpty(searchText)) {
      return allIcons;
    } else {
      return allIcons.filter((option) =>
        option.value.toLowerCase().includes(searchText.toLowerCase())
      );
    }
  }, [allIcons, searchText]);
  const onChangeIcon = (value: string) => {
    const iconName = getFAIconName(value);
    onChange(iconName);
  };
  const clearIcon = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    onChange(undefined);
  };

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const renderIcons = (
    icons: Array<{
      value: string;
      label: JSX.Element;
    }>
  ) => {
    return icons.map((option) => (
      <MenuItem
        value={option.value}
        key={option.value}
        classes={{ root: classes.select }}
        className={`${classes.menuItem} ${
          option.value === selectedOption.value ? `${classes.autoFocusItem}` : ''
        }`}
        onKeyDown={(e) => e.stopPropagation()}
        onKeyUp={(e) => e.stopPropagation()}
        onClick={(e) => {
          const target = e.target as HTMLDivElement;
          const selectedValue = target.textContent;
          if (selectedValue) {
            onChangeIcon(selectedValue);
          }
        }}
        autoFocus={option.value === selectedOption.value}
      >
        <Icon style={Iconstyles.icon}>{option.label}</Icon>
        <Typography style={Iconstyles.text}>{option.value}</Typography>
      </MenuItem>
    ));
  };

  if (listRef.current) {
    const rect = listRef.current.getBoundingClientRect();
    top = rect.top;
  }

  return (
    <Select
      style={styles.selectField}
      variant="outlined"
      MenuProps={{
        style: styles.dropDown,
        classes: { paper: classes.dropdownStyle },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      value={selectedOption.value}
      onClose={() => setSearchText('')}
      displayEmpty
      renderValue={(value) => {
        return (
          <>
            {!isEmpty(iconValue) ? (
              <div style={Iconstyles.value}>
                <Icon style={styles.iconStyle}>{selectedOption.label as React.ReactElement}</Icon>
                <Typography>{`${value}`}</Typography>
                <div
                  style={Iconstyles.clearIcon}
                  onMouseDown={(e) => {
                    clearIcon(e);
                  }}
                >
                  <FontAwesomeIcon icon={faClose} className={classes.exitIcon} />
                </div>
              </div>
            ) : (
              <Placeholder>Select Icon</Placeholder>
            )}
          </>
        );
      }}
      classes={{ root: !isEmpty(iconValue) ? classes.rootselect : classes.rootPadding }}
    >
      <ListSubheader className={classes.root}>
        <Button
          onClickCapture={stopImmediatePropagation}
          onKeyDown={(e) => e.stopPropagation()}
          onKeyUp={(e) => e.stopPropagation()}
          disableRipple
          autoFocus
        >
          <TextField
            autoFocus
            placeholder="Search"
            fullWidth
            className={classes.input}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} className={classes.searchIcon} />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            onChange={(e) => {
              e.stopPropagation();
              setSearchText(e.target.value);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Button>
        <Divider className={classes.dividerPosition} />
      </ListSubheader>
      {isEmpty(filteredIcons) ? (
        searchText.length > 0 && (
          <MenuItem value="" disabled className={[classes.emptyVal, classes.menuItem].join(' ')}>
            <Typography>No Item Found</Typography>
          </MenuItem>
        )
      ) : (
        <ListContainer ref={listRef} top={top + offset}>
          {renderIcons(filteredIcons)}
        </ListContainer>
      )}
    </Select>
  );
};

const Iconstyles = {
  text: {
    marginLeft: 18,
    color: colors.black,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '100%',
  },
  icon: {
    width: 30,
    height: 30,
    color: colors.black,
  },
  clearIcon: {
    background: colors.gray10,
    height: 26,
    width: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  value: {
    border: `1px solid ${colors.gray88}`,
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: 5,
    height: 26,
    gap: 12,
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&.MuiListSubheader-sticky': {
        backgroundColor: colors.white,
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        height: 40,
      },
      '& .MuiButton-root': {
        height: 40,
        width: '100%',
        paddingInline: 24,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& .Mui-focusVisible, .Mui-selected, &.Mui-selected:hover': {
        backgroundColor: 'transparent',
      },
    },
    input: {
      '& .MuiInput-root': {
        height: 40,
      },
      '& input::placeholder': {
        color: colors.black45,
        fontSize: 15,
        fontWeight: 400,
        lineHeight: '100%',
      },
    },
    dividerPosition: {
      position: 'sticky',
      zIndex: 999,
      top: 40,
    },
    searchIcon: {
      color: colors.black35,
      marginRight: 3,
    },
    select: {
      paddingLeft: 24,
    },
    rootPadding: {
      padding: '9px 14px',
    },
    rootselect: {
      '&.MuiOutlinedInput-input': {
        padding: '5px 7px 5px 8px',
      },
    },
    emptyVal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 5,
    },
    dropdownStyle: {
      borderRadius: 6,
      backgroundColor: colors.white,
      boxShadow: `0px 1px 4px 0px ${colors.black25}`,
      '& .MuiList-padding': {
        paddingTop: 0,
      },
      overflow: 'hidden',
    },
    exitIcon: {
      height: 18,
      width: 18,
      flexShrink: 0,
      color: colors.black54,
    },
    menuItem: {
      color: colors.black,
      width: '98%',
      margin: 'auto',
      borderRadius: 5,
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.light, 0.2),
      },
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: alpha(theme.palette.primary.light, 0.2),
      },
    },
    autoFocusItem: {
      backgroundColor: alpha(theme.palette.primary.light, 0.2),
    },
  })
);

const ListContainer = styled.div<{ top: number }>`
  max-height: calc(100vh - ${({ top }) => top}px);
  overflow-y: scroll;
`;
