import React, { useState } from 'react';
import { V2PieChartOptions } from '@terragotech/page-renderer';
import { TGIosSwitch, TGSelect } from '../../../views/components/formElements';
import { ChartEditSectionProps } from './common';

const layersOptions: Record<NonNullable<V2PieChartOptions['layers']>[number], string> = {
  arcs: 'Arcs',
  arcLabels: 'Arc Labels',
  arcLinkLabels: 'Arc Link Labels',
  legends: 'Legends',
};

export const PieChartEditSection: React.FC<ChartEditSectionProps<'pie'>> = ({
  chartOptions,
  setChartOptions,
  containerCss,
  containerStyle,
  labelCss,
  labelStyle,
}) => {
  const [layers, setLayers] = useState(chartOptions?.layers);
  const [sortByValue, setSortByValue] = useState(chartOptions?.sortByValue);

  return (
    <>
      <TGSelect
        id="layers"
        label="Layers"
        type="layer(s)"
        multiple={true}
        value={layers ?? []}
        options={layersOptions}
        onChangeMultiple={(valueArray) => {
          const value = valueArray.length === 0 ? undefined : valueArray;
          setLayers(value as V2PieChartOptions['layers']);
          setChartOptions((o) => ({ ...o, layers: value as V2PieChartOptions['layers'] }));
        }}
        containerStyle={containerStyle}
        containerCss={containerCss}
        labelStyle={labelStyle}
        labelCss={labelCss}
      />
      <TGIosSwitch
        title="Sort By Value"
        description="Order arcs according to their associated value instead of order within chart data."
        checked={sortByValue ?? false}
        onChange={(value) => {
          setSortByValue(value as V2PieChartOptions['sortByValue']);
          setChartOptions((o) => ({
            ...o,
            sortByValue: value as V2PieChartOptions['sortByValue'],
          }));
        }}
        containerClass={containerStyle}
        containerCss={containerCss}
        labelClass={labelStyle}
        labelCss={labelCss}
      />
    </>
  );
};

export default PieChartEditSection;
