import React, { useState } from 'react';
import { errorMsg, successMsg } from '../../../../../components/SnackbarUtilsConfigurator';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useCustomPageAPI } from '../../../../../context/fakeAPIHooks/useCustomPageAPI';
import _, { chain, get, size } from 'lodash';
import { KeyboardArrowRightOutlined } from '@material-ui/icons';
import { CONFIRMATION, UIPAGE_HEADER_HEIGHT, UI_MENU_CUSTOM_PAGES, UI_ROUTE } from 'utils/Utils';
import {
  Box,
  Button,
  IconButton,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { colors } from 'utils/colors';
import { useFormDialog } from 'components/FormDialog/FormDialogService';
import { CreateNewCustomPageForm } from 'components/FormDialog/CreateNewCustomPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faMinus } from '@fortawesome/pro-solid-svg-icons';
import { faAdd } from '@fortawesome/pro-regular-svg-icons';
import { usePageGroup } from 'common/usePageGroup';
import { useConfirmDialog } from 'context/ConfirmContext';
import { SearchBar } from 'views/components/SearchBar';

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    flexDirection: 'row-reverse',
    marginBottom: -1,
    padding: 0,
    minHeight: 50,
    height: 50,
    paddingRight: 10,
    marginLeft: 16,
    '&$expanded': {
      minHeight: 45,
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '4px 4px',
      marginLeft: 0,
    },
    '& .MuiIconButton-root': {
      padding: 4,
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
  },
  expandIcon: {
    '&$expanded': {
      transform: 'rotate(90deg)',
    },
  },
  content: {
    marginLeft: 5,
    '&$expanded': {
      margin: '1px 0',
      marginLeft: 5,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    paddingLeft: 21,
    paddingRight: 12,
  },
}))(MuiAccordionDetails);

const CUSTOM_PAGE_ROUTE = `${UI_ROUTE}/${UI_MENU_CUSTOM_PAGES}/`;

export const CustomPagesList: React.FC = () => {
  const CustomPageAPI = useCustomPageAPI();
  const classes = useStyles();
  const navigate = useNavigate();
  const formDialog = useFormDialog();
  const [search, setSearch] = useState('');
  const { customPage, groupName: currentGroupName } = useParams() as {
    customPage: string;
    groupName: string;
  };
  const { pageGroupList, customPages } = usePageGroup();
  const { openConfirmation } = useConfirmDialog();

  const handleDeleteCustomPage = (name: string) => async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const confirmProp = CONFIRMATION.customPage;
    const status = await openConfirmation(confirmProp);
    if (status === 'confirm') {
      const firstCustomPage = chain(pageGroupList)
        .filter((o) => size(o.pageNames) > 0)
        .first()
        .value();
      if (name === customPage)
        navigate(
          `${CUSTOM_PAGE_ROUTE}${firstCustomPage.groupName}/${get(
            firstCustomPage,
            'pageNames.[0]',
            ''
          )}`
        );
      const { error } = await CustomPageAPI.deletePage(name);
      if (error) return;
      successMsg(`The custom page "${name}" has been successfully deleted`);
    }
  };

  const ifCustomPageExists = (pageName: string) => customPages[pageName];

  const isValid = (pageName: string) => {
    if (ifCustomPageExists(pageName)) {
      errorMsg(`An custom page named "${pageName}" already exists`);
      return false;
    }
    return true;
  };

  const handleAddCustomPage = async () => {
    const { pageName, page } = await formDialog<typeof CreateNewCustomPageForm>(
      (props) => <CreateNewCustomPageForm {...props} />,
      true,
      true
    );
    if (!isValid(pageName)) return;
    const { error } = await CustomPageAPI.addPage(pageName, page);
    if (error) return;
    successMsg(`The custom page "${pageName}" has been successfully added`);
  };

  const validCustomPages = pageGroupList.filter((x) => x.pageNames.length > 0);

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.innerContainer}>
        <Box className={classes.header}>
          <Typography className={classes.headerTitle}>Custom Pages</Typography>
          <IconButton onClick={() => handleAddCustomPage()} className={classes.addIconButton}>
            <FontAwesomeIcon icon={faAdd} className={classes.addIcon} />
          </IconButton>
        </Box>
        <SearchBar value={search} onChange={setSearch} />
        <ScrollableTreeContainer>
          {validCustomPages
          .filter(({ pageNames}) => pageNames.some((pageName) => pageName.toLowerCase().includes(search.toLowerCase())))
          .map(({ groupName, pageNames }) => {
            const isExpanded = groupName === currentGroupName;
            const customPageWithGroup = `${CUSTOM_PAGE_ROUTE}${groupName}/`;
            return (
              <Accordion expanded={isExpanded} key={groupName} className={classes.root}>
                <AccordionSummary
                  onClick={() => navigate(`${customPageWithGroup}${pageNames[0]}`)}
                  expandIcon={<KeyboardArrowRightOutlined />}
                >
                  <Box style={styles.headerContainer}>
                    <Typography style={styles.txt}>{groupName}</Typography>
                    <Typography className={classes.count}>{_.size(pageNames.filter((pageName) => pageName.toLowerCase().includes(search.toLowerCase())))}</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    {pageNames
                    .filter((pageName) => pageName.toLowerCase().includes(search.toLowerCase()))
                    .map((name) => (
                      <Button
                        key={name}
                        onClick={() => navigate(`${customPageWithGroup}${name}`)}
                        endIcon={
                          <span
                            className={classes.removeIcon}
                            onClick={handleDeleteCustomPage(name)}
                          >
                            <FontAwesomeIcon icon={faCircle} className={classes.circle} />
                            <FontAwesomeIcon icon={faMinus} className={classes.minus} />
                          </span>
                        }
                        className={`${classes.menu} ${
                          customPage === name ? classes.activeMenu : ''
                        }`}
                      >
                        {name}
                      </Button>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </ScrollableTreeContainer>
      </Box>
    </Box>
  );
};
const ScrollableTreeContainer = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  width: 300px;
  height: 100%;
  background-color: ${colors.white};
  padding-top: 7px;
`;
const styles = {
  emptyContainer: {
    color: colors.black54,
    fontSize: 14,
    textAlign: 'center',
  } as React.CSSProperties,
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 10,
  },
  txt: {
    fontSize: 15,
    lineHeight: '100%',
    color: colors.black,
  },
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      padding: '11px 12px 10px 25px',
      height: 45,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${colors.black10}`,
    },
    root: {
      '&.MuiAccordion-root.Mui-expanded': {
        margin: 0,
      },
    },
    count: {
      position: 'absolute',
      right: 24,
      fontSize: 14,
      color: colors.black50,
    },
    mainContainer: {
      backgroundColor: `${colors.white}`,
      borderRight: `1px solid ${colors.black10}`,
      height: '100%',
    },
    innerContainer: {
      height: `calc(100% - ${UIPAGE_HEADER_HEIGHT}px)`,
    },
    headerTitle: {
      fontSize: 16,
      fontWeight: 500,
    },
    addIconButton: {},
    addIcon: {
      color: theme.palette.primary.main,
      fontSize: 20,
    },
    optionContainer: {
      padding: '8px 18px 8px 45px',
      cursor: 'pointer',
      justifyContent: 'space-between',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
    activeOption: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      height: 40,
    },

    optionTitle: {
      fontSize: '14px',
      fontWeight: 400,
      color: colors.black54,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    menu: {
      width: 262,
      marginLeft: 5,
      justifyContent: 'flex-start',
      paddingLeft: 22,
      color: colors.black54,
      fontSize: 14,
      fontWeight: 400,
      height: 40,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        '& .MuiButton-label': {
          color: theme.palette.primary.main,
        },
      },
    },
    activeMenu: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    removeIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      right: 10,
      top: 9,
    },
    circle: {
      width: 25,
      height: 25,
      color: colors.lavenderBlush,
    },
    minus: {
      position: 'absolute',
      width: 14,
      color: colors.jellyBean,
    },
  })
);
